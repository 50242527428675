import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;

const ftmUserData = localStorage.getItem('ftmUserData');
let accessToken = '';

if (ftmUserData !== undefined && ftmUserData !== null && ftmUserData !== '') {
  const user = JSON.parse(ftmUserData);
  if (user !== undefined && user !== null && user !== '' && user.accessToken !== undefined && user.accessToken !== null && user.accessToken !== '') {
    accessToken = user.accessToken.replace(/['"]+/g, '');
}
}

let api = axios.create({
  baseURL: BASE_URL,
});

if (accessToken) {
  api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

export const get = async (endpoint, query) => {
  try {
    if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
      let token = localStorage.getItem('loginToken');
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    const response = await api.get(endpoint, query);
    return response.data;
  } catch (error) {
    // Handle error or throw an error
    throw new Error('Failed to fetch data');
  }
};

export const post = async (endpoint, data) => {
  try {
      if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
        let token = localStorage.getItem('loginToken');
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    // Handle error or throw an error
    throw new Error('Failed to post data');
  }
};

export const put = async (endpoint, data) => {
  if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
    let token = localStorage.getItem('loginToken');
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  try {
    const response = await api.put(endpoint, data);
    return response.data;
  } catch (error) {
    // Handle error or throw an error
    throw new Error('Failed to put data');
  }
};

export const deleteApi = async (endpoint) => {
  if (api.defaults.headers.common['Authorization'] == undefined || api.defaults.headers.common["Authorization"] == null) {
    let token = localStorage.getItem('loginToken');
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  try {
    const response = await api.delete(endpoint);
    return response;
  } catch (error) {
    // Handle error or throw an error
    throw new Error('Failed to put data');
  }
};