import React, { useState, useEffect, useRef} from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import MapContainer from "../../components/MapContainer";
import ApexChart from "../../components/ApexChart";
import { get, post } from "../../components/api";
import moment from "moment";
import "./styles.css";
import Loading from "../../components/Loading";
import { SweetAlert } from "../../helpers/helper";
import ResponsiveTable from "../../components/ResponsiveTable";
import MapFuelFill from "../../components/MapFuelFill";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import ProgressBar from "../../components/ProgressBar";
import Select from "react-select";
import { RiTruckLine } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";

const FuelTheft = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedVehicleNo, setSelectedVehicleNo] = useState("");
  const [fuelConsumption, setFuelConsumption] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectVehicleNo, setSelectVehicleNo] = useState("");
  const [activeVehicleList, setActiveVehicleList] = useState([]);
  const [vehicleStatus, setVehicleStatus] = useState({
    offline: 0,
    idle: 0,
    moving: 0,
  });
  const [selected, setSelected] = useState({ value: 'today', label: 'Today' });
  const [isLoading, setIsLoading] = useState(false);
  const [fueldata,setFuelData] = useState([]);
  const [finalFuelData,setFinalFuelData] = useState([])
  const [timeLogic,setTImeLogic] = useState({"$gte": moment().startOf('day').toDate(), "$lte": moment().toDate(),})
  const [fuelLocation, setFuelLocation] = useState([])
  const [startDate, setStart] = useState(moment().startOf('day').toDate());
  const [endDate, setEnd] = useState(moment().toDate());
  const [showDatePicker,setShowDatePicker] = useState(false);
  const [vehicleOption,setVehicleOption] = useState([])
  const [reportSelect,setReportSelect] = useState({
    value : "Default",label:"Default"
  })
  const [vehicle,setVehicle] = useState("")
  const [downloadReportLink,setDownloadReportLink] = useState("")
  const daterangepickerRef = useRef(null);
  const vehicleRefs = useRef([]);
  const maxFuelLevel = 411;
  const today = moment().endOf('day');
  const now = moment();
  // rgba(242, 243, 244, 0.5)
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fff",
      border: "none",
      outline: "none",
      fontSize: "0.8rem",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "0.8rem",
    }),
  };
  let userData = localStorage.getItem("ftmUserData");

  if (userData) {
    try {
      userData = JSON.parse(userData);
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
  } else {
    console.log("No user data found in localStorage");
    navigate("/login");
  }
  const handleFuelStats = async () => {
    setLoading(true)
    const apiResponse = await post("/fuel-level-stats-halfhour", {
      vehicleNo: selectVehicleNo,
      timeLogic:timeLogic
    });
    if (apiResponse.status == 200) {
        const FuelDataArray = apiResponse?.fuelData
        const arrayOfObjects = apiResponse.coordinates?.map(([lat, lng]) => ({ lat, lng }));
        const combinedArray = arrayOfObjects?.map((coords,index) => ({
          ...coords,
          address:FuelDataArray[index].Location,
          FuelConsumed:FuelDataArray[index].fuelFill,
          fuelTheft:FuelDataArray[index].fuelTheft
        }))
      setFuelConsumption(apiResponse.data);
        setFuelData(apiResponse?.fuelData)
        setFinalFuelData(apiResponse?.fuelData)
      setFuelLocation(combinedArray)
      setDownloadReportLink(apiResponse?.excel)
      setLoading(false)
    } else {
      setLoading(false)
      setFuelConsumption([]);
    }
  };

  useEffect(() => {
    if(reportSelect.value === "Default") {
      setFinalFuelData(fueldata)
    }else if (reportSelect.value === "Fill"){
      const updatedData = fueldata.filter(data => data.fuelFill !== "NA");
      const filterData =  updatedData.map(({ fuelTheft, ...rest }) => rest)
      setFinalFuelData(filterData);
    } else if (reportSelect.value === "Theft"){
      const updatedData = fueldata.filter(data => data.fuelTheft !== "NA");
      const filterData =  updatedData.map(({ fuelFill, ...rest }) => rest)
      setFinalFuelData(filterData);
    }
  },[reportSelect])

  const handleActiveVehicle = async () => {
    setLoading(true);
    const apiResponse = await post("/get-most-active-vehicle", {});
    if (apiResponse.status == 200 && apiResponse.data.length > 0) {
      const filteredData = apiResponse.data.filter(vehicle => vehicle.isFuel);
      setActiveVehicleList(filteredData);
      setVehicleList(filteredData);
      setActiveVehicleList(filteredData);
      setVehicleList(filteredData);
      console.log(filteredData[0].vehicleNo)
      setSelectVehicleNo(filteredData[0].vehicleNo)
      setVehicle({value : filteredData[0].vehicleNo , label : filteredData[0].vehicleNo})
      setLoading(false);
    } else {
      setActiveVehicleList([]);
      setVehicleList([]);
      setLoading(false);
    }
  };
 

  useEffect(() => {
    setIsLoading(true);
    handleActiveVehicle();
    setIsLoading(false);
  }, []);
 
  useEffect(() => {
    setIsLoading(true);
    handleFuelStats();
    setIsLoading(false);
  },[selectVehicleNo,timeLogic])


  const initialSettings = {
    startDate: startDate,
    endDate: endDate,
    timePicker: true,
    timePicker24Hour: false,
    alwaysShowCalendars: true,
    maxDate: today.toDate(),
    maxTime: now.format('HH:mm'),
    locale: {
      format: 'MMMM D, YYYY HH:mm', // 12-hour format
    },
    opens: 'left',
  }

  const handleApplyDate = (event, picker) => {
    const selectedStart = moment.utc(picker.startDate);
    const selectedEnd = moment.utc(picker.endDate);

    if (selectedEnd.isBefore(selectedStart.clone().add(1, 'months'))) {
      setTImeLogic({"$gte": selectedStart, "$lte": selectedEnd,})
    } else {
      SweetAlert("Please select a date range within one month from the start date.", true);
    }
  };

  const fewRange = [
      { value: 'today', label: 'Today' },
      { value: 'yesterday', label: 'Yesterday' },
      { value: 'last7days', label: 'Last 7 Days' },
      { value: 'last10days', label: 'Last 10 Days' },
      // { value: 'custom', label: 'Custom Date Range' }
    ];
  
    const getDateRange = (selection) => {
      const now = moment.utc();
      let gte;
      let lte = now.format(); // ISO 8601 format with time
    
      switch (selection) {
        case 'today':
          gte = now.startOf('day').subtract(5, 'hours').subtract(30, 'minutes').format();
          break;
        case 'yesterday':
          gte = now.subtract(1, 'day').startOf('day').subtract(5, 'hours').subtract(30, 'minutes').format();
          break;
        case 'last7days':
          gte = now.subtract(7, 'days').startOf('day').subtract(5, 'hours').subtract(30, 'minutes').format();
          break;
        case 'last10days':
          gte = now.subtract(10, 'days').startOf('day').subtract(5, 'hours').subtract(30, 'minutes').format();
          break;
        default:
          return {};
      }
    
      return {
        $gte: gte,
        $lte: lte,
      };
    };
  
    const handleChange = (selected) => {
      setSelected(selected);
      
    if (selected.value === 'custom') {
      setShowDatePicker(true); // Show date range picker
      triggerClick()
      // daterangepickerRef.current?.click();
      // daterangepickerRef.current?.setStartDate()
    } else {
      setShowDatePicker(false); // Hide date range picker
      const dateRange = getDateRange(selected.value);
      setTImeLogic(dateRange);
    }
    };
  
    const triggerClick = () => {
      if (daterangepickerRef.current) {
        // Manually trigger the click event
        daterangepickerRef.current.click();
      }
    }

  // Line Chart
  const Lineseris = [
    {
      name: "GJ27TD6391",
      data: fuelConsumption?.reverse(),
    },
  ];
  const LineOptions = {
    chart: {
      type: "area",
      stacked: false,
      height: 250,
      background: 'white',
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        autoSelected: "zoom",
        tools: {
          download: true,
          zoom: false,
          pan: false,
          reset: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    colors: ['#2A7C76'],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
  },
    yaxis: {
        min: 0,
        max: 420,
        labels: {
            formatter: function (val) {
              return Math.round(val / 10) * 10;
            },
      },
      title: {
        text: "Diesel Level",
      },
    },
    xaxis: {
      labels :{
        formatter: function (val) {
          return `${moment(val).format('HH')}:00`
        }
      },
      type: "datetime",
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return moment(val).format('MMM D, YYYY HH:mm:ss'); // Format date and time
        }
      },
      y: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  };

  const filterVehiclesByVehicleNo = (vehicles, searchString) => {
    const lowerCaseSearchString = searchString.toLowerCase();
    const searchedVehicle = vehicles.filter((vehicle) =>
      vehicle?.vehicleNo?.toLowerCase().includes(lowerCaseSearchString)
    );
    const searchedVehicleByTag = vehicles.filter((vehicle) =>
      vehicle?.tags?.some((tag) => tag.tagname.includes(lowerCaseSearchString))
    );
    const uniqueItems = [...searchedVehicle, ...searchedVehicleByTag].filter(
      (item, index) =>
        [...searchedVehicle, ...searchedVehicleByTag].indexOf(item) === index
    );
    return uniqueItems;
  };

  const searchVehicle = (event) => {
    if (event.target.value !== "") {
      const filteredVehicles = filterVehiclesByVehicleNo(
        activeVehicleList,
        event.target.value
      );
      setActiveVehicleList(filteredVehicles);
    } else {
      setActiveVehicleList(vehicleList);
    }
  };

  const selectVehicle = (vehicleNo) => {
    console.log(vehicleNo)
    setSelectVehicleNo(vehicleNo);
  };

  useEffect(() => {
    setVehicleOption(createVehicleOption());
}, [activeVehicleList]);
  

  const createVehicleOption = () => {

 return activeVehicleList.map((vehicle) => 
    ({ value: `${vehicle.vehicleNo}`,label: `${vehicle.vehicleNo}`,id:vehicle._id }));
  
  }
  const handleVehicleNo = (vehicleObj) => {
    if (vehicle.value !== vehicleObj.value){
      setVehicle(vehicleObj)
      selectVehicle(vehicleObj.value)
      const newArray = vehicleObj.value ? [activeVehicleList.find(v => v.vehicleNo === vehicleObj.value), ...activeVehicleList.filter(v => v.vehicleNo !== vehicleObj.value)] : activeVehicleList;
      setActiveVehicleList(newArray)
    }
  }

  const reportoption = [
    {
      value : "Default",label:"Default"
    },
    {
      value : "Fill",label:"Fill"
    },
    {
      value : "Theft",label:"Theft"
    },
  ]

  const handleReport = (reportObj) => {
    setReportSelect(reportObj)
  }
  // fiilter vehicle by status
  // style={{height : "490px"}}
  const inputClick = (event) => {console.log(event)};
  return (
    <div className="dashboard">
      {!isLoading ? (
        <>
          <div className="row mb-5">
            <div className="col-md-5 col-lg-4 col-xs-12" >
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      <IoSearch />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder= "Search Vehicle"
                      aria-label="Vehicle"
                      aria-describedby="basic-addon1"
                      onChange={searchVehicle}
                    />
                  </div>
                  <div className="vehicle-list-parent">
                    {activeVehicleList.length <= 0 && loading && <Loading />}
                    {activeVehicleList.length <= 0 && !loading && (
                      <div className="" style={{marginLeft : "15px"}}>No vehicles found</div>
                    )}
                    {activeVehicleList.length > 0 &&
                      !loading && (
                      
                      // filteredVehicles.length > 0 ? (
                        activeVehicleList?.map((vehicle, index) => (
                        <div
                          class={
                            vehicle.vehicleNo == selectVehicleNo
                              ? `card text-center mb-2 selected-vehicle-fuelAnalytics`
                              : `card text-center mb-2`
                          }
                          key={index}
                          // ref={(el) => (vehicleRefs.current[vehicle.vehicleNo] = el)}
                        >
                          <div class="card-body">
                            <div className="vehicle-details">
                            <div className="d-flex">
                            <RiTruckLine style={{marginRight:"5px"}}/>
                              <p className="mb-1 mr-1 vehicle-no">
                              {vehicle?.vehicleNo}
                              </p>
                            </div>
                              <div className="d-flex align-items-center">
                                <p className="mb-1 vehicle-no">Fuel Level: {vehicle?.fuel}L/{vehicle?.maxTankCapacity}L</p>
                              </div>
                              <ProgressBar value={vehicle?.fuel} maxValue={vehicle.maxTankCapacity} />
                            </div>
                          </div>
                        </div>
                        )))
                      }
                        </div>
                </div>
                
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xs-12">
              <div className="row">
              <div className="col-md-12 col-lg-12 col-xs-5 mt-1 dropdown-analytics">
              <div style={{width:"20%"}}>
                    <Select 
                      styles = { customStyles }
                      options={vehicleOption}
                      onChange={(e) => handleVehicleNo(e)}
                      value={vehicle}
                      maxMenuHeight={150}
                      placeholder="Select your vehicle"
                    />   
                    </div>
                <div style={{width:"20%"}}>
                <Select
                      value={selected}
                      onChange={handleChange}
                      options={fewRange}
                      styles={customStyles}
                      placeholder="Select a time period"
                      />
                      {showDatePicker && <DateRangePicker
                    // ref={daterangepickerRef}
                    className="custom-date-range"
                    initialSettings={initialSettings}
                    onApply={handleApplyDate}
                    >
                      {/* <></> */}
                    <input type="text" placeholder="Today" className="form-control" style={{width: '350px' , display:'block'}} onClick={inputClick} ref={daterangepickerRef} />
                    </DateRangePicker>}
                </div>            
                   </div>
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="card custom-card mt-1">
                    <div className=" card custom-card">
                      {vehicleList.length > 0  ? <MapFuelFill
                        locations1={fuelLocation}
                        selectVehicleNo={selectVehicleNo}
                      /> : <div className="card-body">No records found</div>}
                    </div>
                    </div> 
                    </div>
                </div>
              </div>
            <div className="col-md-12 col-lg-12 col-xs-8 mt-3">
            {loading ? <div className="card" style={{width:"100%" , height:"30%"}}><Loading/></div> : <div className="pi-chart-wrapper">
                      <ApexChart
                        series={Lineseris}
                        options={LineOptions}
                        type={"area"}
                        height={240}
                      />
                    </div>}
            
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="mb-2" style={{ width: '20%' }}>
                  <Select
                    styles={customStyles}
                    options={reportoption}
                    onChange={(e) => handleReport(e)}
                    value={reportSelect}
                    maxMenuHeight={150}
                    placeholder="Select your vehicle"
                  />
               </div>
              </div>
              {loading ? <div className="card" style={{width:"100%" , height:"30%"}}><Loading/></div> : <div className="card custom-card">
                    <div className="card-body" style={{display:"flex" , justifyContent:"space-between"}}>
                      <h6>
                        Refuel Alerts
                      </h6>
                      {console.log(downloadReportLink !== "")}
                      {downloadReportLink !== "" && <a download href={downloadReportLink}>
                        <SiMicrosoftexcel />
                      </a>}
                    </div>
                    {console.log(finalFuelData.length)} 
                    {finalFuelData.length === 0 && <div> <p style={{marginLeft:"15px"}}> No filling for this duration </p></div>}
                    <div className="pi-chart-wrapper">
                    <ResponsiveTable data={finalFuelData} />
                    </div>
                  </div>}
                  
              </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default FuelTheft;
