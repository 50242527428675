import React, { useEffect, useState } from 'react';
// import { Circle,Polygon, DrawingManager, GoogleMap, useLoadScript } from '@react-google-maps/api';
import { Circle,GoogleMap,Polygon,useLoadScript } from '@react-google-maps/api';
import { get, post } from '../../components/api';
import { showConfirmDialog, SweetAlert } from '../../helpers/helper';
import { IoSearch } from 'react-icons/io5';
import { MdShareLocation } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { LuTimer } from 'react-icons/lu';
import moment from 'moment';
import Translate from '../../components/Translate';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const libraries = ['places','drawing'];

const GeofenceDashboard = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries,
      });
      const navigate = useNavigate()
      const mapOptions = {
        mapTypeId: "roadmap",
        zoomControl: true,
        streetViewControl: false, 
        fullscreenControl: false, 
        mapTypeControl: false, 
        trafficControlOptions: {
          mapTypeIds: ['roadmap', 'satellite'],
          style: window.google.maps.TrafficStyle
        }
      }
    const mapContainerStyle = {
        width: '100%',
        height: '450px',
      };

      const [data, setData] = useState([])
      const [latitude, setLatitude] = useState(null);
      const [longitude, setLongitude] = useState(null);
      const [paths, setPaths] = useState([]);
      const [geofencetype, setGeofenceType] = useState("")
      const [geofencename, setGeofenceName] = useState("")

      useEffect(() => {
        const fetchGeofenceData = async () => {
          try {
            const ftmUserData = JSON.parse(localStorage.getItem('ftmUserData'));
            const companyid = ftmUserData.id;
            const apiResponse = await post('admin/get-All-geofence', { companyid });
            if (apiResponse.status === 200){
                setData(apiResponse.data)
              // await get('admin/in-geofence')
            } 
            else if (apiResponse.status === 201){
                SweetAlert(apiResponse.message,true)
            }

          } catch (error) {
            console.error('Error fetching geofence data:', error);
          }
        };
    
        fetchGeofenceData();
       
      }, []);

      const convertCoordinatesToPaths = (coordinates) => {
        const set = coordinates.map(coord => ({
          lat: coord[1],
          lng: coord[0]
        }));
        console.log(set)
        return set
      };

      const selectGeofence = ({ geofence }) => {
        const {paths,geofencetype,latitude,longitude,geofencename} = geofence;
        console.log("geofence",geofence)
            setLatitude(latitude);
            setLongitude(longitude);
            setGeofenceType(geofencetype);
            let radius = paths?.radius
            paths?.center?.length > 0 ? setPaths([{ lat: paths?.center[1], lng: paths?.center[0], radius }]) : setPaths(convertCoordinatesToPaths(paths?.coordinates[0]))
            setGeofenceName(geofencename)

      }

      const IconWithAddress = ({ geofence }) => {
        const { location, searchtype, latitude, longitude } = geofence;
      
        // Conditionally format the display text
        const displayText = searchtype === 'place'
        ? location
          : `lat:${latitude}, lng:${longitude}` ;
        return (
          <div className="d-flex align-items-start">
            <FiMapPin style={{ fontSize: '1em' }} />
            {/* Adjust the size as needed */}
            <span className="ml-2 today-km w-100">{displayText}</span>
          </div>
        );
      };
      const filterGeofence = (data, searchString) => {
        const lowerCaseSearchString = searchString.toLowerCase();
        return data.filter(geofence =>
            geofence?.geofencename?.toLowerCase().includes(lowerCaseSearchString)
          );
        // const searchGeofence = geofences.filter((geofence) =>
        //   geofence?.geofencename?.toLowerCase().includes(lowerCaseSearchString)
        // );
        // const filteredGeofence = [...searchGeofence]
          
        // return filteredGeofence;
      };

      const searchGeofence = (event) => {
        if (event.target.value !== "") {
          const filteredGeofence = filterGeofence(
            data,
            event.target.value
          );
          setData(filteredGeofence);
        } else {
          setData(data);
        }
      };

      const handleDelete =async (geofenceId) => {
        const confirmed = await showConfirmDialog(
          "Delete!",
          "Are you sure you want to delete geofence?"
        );
        if(confirmed) {
          const apiResponse = await post("admin/delete-geofence",{geofenceId})
        console.log(apiResponse)
        if(apiResponse.status === 200){
          SweetAlert(apiResponse?.message,false)
        }else{
          SweetAlert(apiResponse?.message,true)
        }
        }
      }
if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }
  return (
    <>
    <div className='dashboard'>
        <div className="row mb-5">
            <div className="col-md-5 col-lg-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      <IoSearch />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder= "Search Vehicle"
                      aria-label="Vehicle"
                      aria-describedby="basic-addon1"
                      onChange={searchGeofence}
                    />
                  </div>
                  <div className="vehicle-list-parent">
                    {/* {data.length <= 0 && loading && <Loading />} */}
                    {data.length <= 0 && (
                      <div className="">No geofence found</div>
                    )}
                    {data.length > 0 &&
                      data?.map((geofence, index) => (
                        <div
                        // className='card text-center mb-2 cursor-pointer'
                        key={index}
                          class={
                            geofence.geofencename == geofencename
                              ? `card text-center mb-2 selected-vehicle cursor-pointer`
                              : `card text-center mb-2 cursor-pointer`
                          }
                          onClick = {() => selectGeofence({geofence})}
                        >
                          <div class="card-body">
                            <div style={{display:"flex" ,  justifyContent: "flex-end", gap : "5px" }}>
                              <Link to={`/geofence/edit`} state={{ geofence: geofence }} className="" style={{marginTop:"-5px"}}><FaEdit/></Link>
                              <MdDelete onClick={() => handleDelete(geofence._id)}/>
                            </div>
                            <div className="vehicle-details">
                            <div className="d-flex align-items-start gap-1">
                              <MdShareLocation />
                              <p className="mb-1 vehicle-no">
                                {geofence?.geofencename}
                              </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <LuTimer size={16} />
                                <p className="mb-1 ml-2 today-km">
                                  {moment(geofence?.createdAt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </p>
                              </div>
                              <IconWithAddress geofence={geofence} />
                              <div className="d-flex align-items-start">
                                <p className='mb-1 ml-2 today-km'>
                                  Vehicles is inside of Geofence
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="card custom-card">
                    <div className="card-header d-flex justify-content-between">
                      <h6><Translate>map</Translate></h6>
                      <div className='d-flex'>
                        <Link to={"/geofence/create"} className="mb-1 vehicle-filter">Create new geofence</Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <GoogleMap
                        zoom={12}
                        mapContainerStyle={mapContainerStyle}
                        center={{ lat: paths[0]?.lat || 22.9 , lng: paths[0]?.lng || 72.497039  }}
                        options={mapOptions}
                        >
                        {geofencetype === "" ? 
                        <></> 
                        :
                        geofencetype === "circle" ?
                        <Circle
                            center={{ lat: paths[0]?.lat, lng: paths[0]?.lng }}
                            radius={paths[0]?.radius }
                            options={{
                            fillColor: '#2A7C76',
                            strokeColor: '#1b1b1b',
                            fillOpacity: 0.5,
                            strokeWeight: 1,
                            }}
                        />
                        : 
                        <Polygon
                        path={paths}
                        options={{
                            fillColor: '#2A7C76',
                            strokeColor: '#1b1b1b',
                            fillOpacity: 0.5,
                            strokeWeight: 1,
                            }}
                        />
                        }
                            
                        </GoogleMap>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </>
  )
}

export default GeofenceDashboard