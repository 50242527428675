import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import SVG from "react-inlinesvg";
import View from "../assets/emergency_services/view.svg";
import RunningVehicle from "../assets/images/running_vehicle.png";
import IdleVehicle from "../assets/images/idle_vehicle.png";
import StoppedVehicle from "../assets/images/stopped_vehicle.png";
import { Link } from "react-router-dom";

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '450px',
};

export default function MapContainer(props) {
  const { locations1, selectVehicleNo } = props;
  const [mapType, setMapType] = useState('roadmap');
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
  const mapRef = useRef(null);

  useEffect(() => {
    // Find the index of the selected vehicle based on its vehicle number
    const selectedIndex = locations1.findIndex(markerData => markerData.vehicleNo === selectVehicleNo);
    setSelectedMarkerIndex(selectedIndex !== -1 ? selectedIndex : null);
  }, [selectVehicleNo, locations1]);

  useEffect(() => {
    if (selectedMarkerIndex !== null && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      if (locations1[selectedMarkerIndex]?.lat && locations1[selectedMarkerIndex]?.lng) {
        bounds.extend(new window.google.maps.LatLng(locations1[selectedMarkerIndex].lat, locations1[selectedMarkerIndex].lng));
        mapRef.current.fitBounds(bounds);
      }
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      if (locations1[0]?.lat && locations1[0]?.lng && mapRef.current) {
        bounds.extend(new window.google.maps.LatLng(locations1[0].lat, locations1[0].lng));
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(10);
      }
    }
  }, [selectedMarkerIndex, locations1]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const toggleMapType = (type) => {
    if (type == 'view-type') {
      const newMapType = mapType === 'roadmap' ? 'satellite' : 'roadmap';
      setMapType(newMapType); // Update mapType state
    }
  };

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: true,
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    trafficControlOptions: {
      mapTypeIds: ['roadmap', 'satellite'],
      style: window.google.maps.TrafficStyle
    }
  }

  const handleToggleInfoWindow = (index) => {
    setSelectedMarkerIndex(index);
  }
  
  return (
    <div>
       <GoogleMap
          onLoad={map => (mapRef.current = map)}
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={{lat: locations1[0]?.lat, lng: locations1[0]?.lng}}
          options={mapOptions}
        >
          <MarkerClusterer>
          {(clusterer) =>
            locations1.map((markerData, index) => (
              <Marker 
                key={markerData?.vehicleNo}
                icon={{
                  url: markerData.vehicleStatus == 'moving' ? RunningVehicle : markerData.vehicleStatus == 'idle' ? IdleVehicle : StoppedVehicle,
                  scaledSize: { width: 50, height: 50 },
                  scale: 7
                }}
                position={{ lat: markerData.lat, lng: markerData.lng }} 
                clusterer={clusterer} 
                onClick={() => handleToggleInfoWindow(index)}
              >
                {selectedMarkerIndex !== '' && selectedMarkerIndex == index && (
                  <InfoWindow
                    onCloseClick={() => handleToggleInfoWindow('')} // Close info window on click outside
                  >
                    <div style={{ color: '#fff', width: '250px', display: 'flex', flexDirection: 'row', textAlign: 'left', padding: '5px', alignItems: "baseline"}} className="live">
                        <div>
                          <button  className='speed-button'>{markerData?.speed?.speed || 0}</button>
                        </div>
                      <div style={{padding : '5px'}} className="live">
                      <Link to={`/vehicles/${markerData.vehicleNo}`} state={{ id: markerData.id }} className="text-white font-600">
                        <p className='mb-2'>{markerData.vehicleNo}</p>
                      </Link>
                        <p>{markerData.address}</p>
                      </div>
                      </div>
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
        </MarkerClusterer>
        </GoogleMap>
        <div className='map-custom-icons-dashboard'>
          <div className={mapType == 'roadmap' ? `image-container mt-3 mb-2` : `image-container-active mt-3 mb-2`} onClick={() => toggleMapType('view-type')}>
            <SVG src={View} width={'20px'} />
          </div>
        </div>
    </div>
  );
}