import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { post } from "../../components/api";
import { SweetAlert } from "../../helpers/helper";
import LoginBackground from "../../assets/login-background.png";
import FSLogo from "../../assets/fslogo.png";

const RequestOtp = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem('username');
  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [resendClicked, setResendClicked] = useState(false);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (!username) {
      navigate('/login');
    }
  }, [username, navigate]);
 
  useEffect(() => {
    const interval = setInterval(() => {
        if (resendClicked) {
          setSeconds(60); // Reset timer when Resend OTP button is clicked
          setResendClicked(false); // Reset resendClicked state
        } else {
          setSeconds(prevSeconds => prevSeconds - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
  }, [resendClicked]);

  const handleOtpChange = (value) => {
    // Ensure only numeric characters are allowed
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
    setOtp(numericValue);
  };

  const submit = async () => {
   if (otp == undefined && otp == null && otp == '') {
    SweetAlert("Please enter OTP", true);
   } else if (otp.length < 6) {
    SweetAlert("Please enter a valid 6-digit OTP", true);
   } else {
    try {
      const response = await post("/auth/verify-otp", {
        'username': username,
        'otp': parseInt(otp)
      });
      if (response.status === 200) {
        SweetAlert(response.message, false);
        localStorage.setItem("ftmUserData", JSON.stringify(response.data))
        localStorage.setItem("loginToken", response.data.accessToken)
        setTimeout(() => {
          setLoader(false);
          navigate('/dashboard');
          localStorage.removeItem('username');
        }, 1000);
      } else {
        SweetAlert(response.message, true);
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoader(false);
    }
   }
  };

  const handleResendOTP = async () => {
    setLoader(true);
    const response = await post("/auth/resend-otp", {
      'username': username
    });
    if (response.status === 200) {
      SweetAlert(response.message, false);
      setLoader(false);
      setResendClicked(true);
    } else {
      SweetAlert(response.message, true);
      setLoader(false);
    }
  };

  // Function to format seconds as mm:ss
  const formatTime = (seconds) => {
    const mm = Math.floor(seconds / 60).toString().padStart(2, '0');
    const ss = (seconds % 60).toString().padStart(2, '0');
    return `${mm}:${ss}`;
  };

  function maskMobileNumber(mobileNumber) {
    if (typeof mobileNumber === 'string' && mobileNumber.length >= 5) {
      // Extracting the last 5 digits of the mobile number
      const lastFiveDigits = mobileNumber.slice(-5);
      // Generating asterisks of the same length as the last 5 digits
      const maskedDigits = '*'.repeat(lastFiveDigits.length);
      // Concatenating the masked digits with the original mobile number, except the last 5 digits
      const maskedNumber = mobileNumber.slice(0, -5) + maskedDigits;
      return maskedNumber;
    } else {
      // If the mobile number is invalid or has less than 5 digits, return the original number
      return mobileNumber;
    }
  }

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: `url(${LoginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        height: "100vh", // Adjust the height as needed
        position: "relative",
      }}
    >
      <div className="row m-0 login-parent">
        <div className="col-md-6 col-lg-6 col-xs-12 login-left-content">
          <img src={FSLogo} alt="" style={{ height: "60px" }} />
          <p className="welcome-text">
            Welcome to <br />
            <span className="font-700">FleetStakes</span>
          </p>
        </div>
        <div className="col-md-4 col-lg-4 col-xs-8 login-right-content">
          <div className="card login-card">
            <div className="card-body p-5">
              <h6 className="text-white text-left mb-4 fs-4">Enter verification code</h6>
                <p className="text-white text-center mb-5">Enter OTP code sent to {maskMobileNumber(username)} </p>
                <div className="text-left mb-3">
                    <OtpInput
                        inputType="tel"
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            width: '2.7rem',
                            height: '2.7rem',
                            margin: '0 0.75rem',
                            border: 'none',
                            borderRadius: '4px',
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between">
                    <p className="text-white">The OTP is valid for {seconds > 0 ? <b>{formatTime(seconds)}</b> : <b>00:00</b> }</p>
                    <a className={seconds > 0 || loader ? 'resend-otp disabled' : 'resend-otp cursor-pointer'} onClick={handleResendOTP}>Resend OTP</a>
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <button type="button" className={otp.length == 6 ? 'btn btn-primary custom-login-btn': 'btn btn-primary custom-login-btn disabled'} onClick={submit}>
                    Verify OTP
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestOtp;
