import React, { useState, useEffect, useRef } from "react";
import {
  Marker,
  GoogleMap,
  useLoadScript,
  DirectionsRenderer,
  InfoWindow
} from "@react-google-maps/api";
import { extractCoordinates } from "../helpers/helper";
import MapIcon from "../assets/images/location.png";
import TollMapIcon from "../assets/images/map-toll-icon.png"

const MapTollContainer = ({ selectedRoute }) => {
  const mapRef = useRef(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [selectedToll, setSelectedToll] = useState(null);

  const mapOptions = {
    zoom: 7,
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    trafficControlOptions: {
      mapTypeIds: ['roadmap', 'satellite'],
      style: window.google.maps.TrafficStyle
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['places']
  });

  useEffect(() => {
    if (isLoaded) {
      const service = new window.google.maps.DirectionsService();
      setDirectionsService(service);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (selectedRoute && directionsService) {
        const route = selectedRoute;
        const request = {
            origin: route?.summary?.origin,
            destination: route?.summary?.destination,
            waypoints: route?.summary?.waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirectionsResponse(result);
            } else {
                console.error('Directions request failed due to ', status);
            }
        });
    }
  }, [selectedRoute, directionsService]);

  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ height: "50vh" }}
        zoom={7}
        center={mapOptions.center}
        options={mapOptions}
        onLoad={(map) => mapRef.current = map}
      >
        <Marker position={selectedRoute?.summary?.origin} options={{ optimized: true }} icon={{ url:MapIcon, scaledSize: new window.google.maps.Size(40, 40) }}></Marker>
        <Marker position={selectedRoute?.summary?.destination} options={{ optimized: true }} icon={{ url:MapIcon, scaledSize: new window.google.maps.Size(40, 40) }} />
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} options={{ suppressMarkers: true }} />}
        {/* toll rendred */}
        {selectedRoute?.tolls.map((toll, index) => (
        <Marker
          key={toll.id}
          position={{ lat: toll.lat, lng: toll.lng }}
          icon={{
            url: TollMapIcon,
            scaledSize: new window.google.maps.Size(30, 30),
            anchor: { x: 15, y: 30 }  // Adjust anchor point if necessary
          }}
          onClick={() => setSelectedToll(toll)}
        />
      ))}
      {selectedToll && (
        <InfoWindow
          position={{ lat: selectedToll.lat, lng: selectedToll.lng }}
          onCloseClick={() => setSelectedToll(null)}
        >
          <div className="custom-info-toll-window">
            <h6>{selectedToll?.name}</h6>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <span>Cash Cost: </span>
                <span>{selectedToll?.currency} {selectedToll?.cashCost}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>FastTag Cost: </span>
                <span>{selectedToll?.currency} {selectedToll?.tagCost}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span style={{width: '80px'}}>Arrival Time: </span>
                <span>{new Date(selectedToll?.arrival.time).toLocaleString()}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span style={{width: '30px'}}>Road:</span>
                <span className="text-right"> {selectedToll?.road} {selectedToll?.state}</span>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
      </GoogleMap>
    </>
  );
};

export default MapTollContainer;
