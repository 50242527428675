// ToastMessage.js
import React from 'react';
import { toast } from 'react-toastify';

const ReactToast = ({ type, title, body }) => {

  const Msg = ({ title, content }) => (
    <div className='text-left'>
     <h6 className='mb-0 react-toast-title'>{title}</h6>
     <p className='mb-0 react-toast-body'>{content}</p>
    </div>
  );

  const showToast = () => {
    switch (type) {
      case 'success':
        toast.success(<Msg title="sedhal" content="Please read my message" />);
        break;
      case 'error':
        toast.error(body, { title });
        break;
      case 'warning':
        toast.error(body, { title });
        break;
      // You can add more cases for other types like info, warning, etc.
      default:
        toast(body, { title });
    }
  };
  return showToast(); 
};

export default ReactToast;
