import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToast from './components/ReactToast';
import {onMessageListener, requestPermission} from './components/firebase';
import BaseRoutes from './routes/routes';
import './App.css';

function App() {
  const [notification, setNotification] = useState();

  useEffect(()=> {
    requestPermission();
  }, []);

  onMessageListener().then((payload)=> {
    setNotification({
      type: payload?.data?.type,
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
  }).catch((error)=> {
    console.log("error ", error)
  })

  useEffect(() => {
    if (notification?.title) {
      let type = '';
      if (notification?.type == 'IGNITION_ON') {
        type = 'success';
      } else if (notification?.type == 'IGNITION_OFF') {
        type = 'error'
      } else if (notification?.type == 'LOW_FUEL') {
        type = 'warning'
      } else if (notification?.type == 'SUDDEN_FUEL_DROP') {
        type = 'error'
      } else if (notification?.type == 'FUEL_FILLED') {
        type = 'success'
      }
      ReactToast({ type: type, title: notification.title, body: notification.body });
    }
  }, [notification]);

  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <BaseRoutes/>
    </div>
  );
}

export default App;
