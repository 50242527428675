import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Translate from "../../components/Translate";

const FleetReports = () => {
  const reportList = [
    { value: "24Hours", slug: "today-report", label: "hourFleetReport", description :"hourFleetReportDetail" },
    { value: "Monthly", slug: "monthly-report", label: "monthlyReport", description :"monthlyReportDetail" },
    { value: "fuelConsumption", slug: "fuel-consuption-report", label: "fuelConsumptionReport",description :"fuelConsumptionDetail" },
    { value: "idle", slug: "idle-duration-report", label: "idleDuration", description :"idleDurationDetail" },
    { value: "ignition", slug: "ignition-report", label: "ignitionReport", description :"ignitionReportDetail" },
    { value: "overspeed", slug: "overspeeding-report", label: "overspeed", description :"overSpeedDetail" },
    { value: "SummaryReport", slug: "Summary-Report", label: "SummaryReport", description :"SummaryReportDetails" },
  ];

  return (
    <div className="reportsWrapper">
      <div className="row">
        {reportList.map((report, i) => (
           <div className="col-md-6 col-lg-4 col-xs-12 mb-3" key={i}>
            <div className="card">
              <div class="card-body">
                <div className="reports text-left">
                  <p className="report-title"><Translate>{report?.label}</Translate></p>
                  <p className="report-desc"><Translate>{report?.description}</Translate></p>
                  <Link to={'/fleet-reports/' + report.slug}><Translate>viewDetail</Translate></Link>
                </div>
              </div>
            </div>
           </div>
        ))}
      </div>
    </div>
  );
};

export default FleetReports;