import React from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

function ModalPopup(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='add-vehicle-modal'>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col>
              {props.children} {/* Dynamic form content */}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalPopup;
