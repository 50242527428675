import Swal from "sweetalert2";
import englishJson from '../assets/englishTranlation.json';
import hindiJson from '../assets/hindiTranslation.json';

function SweetAlert(title, isError) {
  Swal.fire({
    toast: true,
    title: title,
    icon: isError ? "error" : "success",
    width: "25rem",
    position: "top-right",
    timer: 2000,
    showCloseButton: true,
    timerProgressBar: true,
    showConfirmButton: false,
    showCancelButton: false,
    customClass: {
      popup: "colored-toast",
    },
    showClass: {
      popup: "animate__animated animate__bounceInRight",
    },
    hideClass: {
      popup: "animate__animated animate__bounceOutRight",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
}

const showConfirmDialog = async (
  title,
  text,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel"
) => {
  const result = await Swal.fire({
    title: title,
    text: text,
    //icon: "question",
    showCancelButton: true,
    confirmButtonColor: '#2A7C76',
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
  });
  // Return a promise that resolves with the confirm result
  return result.isConfirmed;
};

const getUserIdFromLC = ()=> {
  let ftmUser = localStorage.getItem('ftmUserData');

  if (ftmUser.length == 0 ) {
    return 0
  } else {
    const userJson = JSON.parse(ftmUser);
    return userJson.id
  }
}

const translate = (lang, keyName) => {
  if (lang == 'english') {
    return englishJson[keyName]
  } else {
    return hindiJson[keyName]
  }
}

function extractCoordinates(url) {
  // Create a URL object
  const urlObj = new URL(url);
  // Extract the search parameters
  const params = new URLSearchParams(urlObj.search);
  // Get the origin from 'saddr'
  const origin = params.get('saddr');
  // Get the 'daddr' parameter, which includes the destination and waypoints
  const daddr = params.get('daddr');
  if (!daddr) {
    return {
        origin: origin,
        destination: null,
        waypoints: []
    };
  };
  const parts = daddr.split(' to:');
  const destination = parts.pop();
  const waypoints = parts.map(part => {
    const [lat, lng] = part.split(",");
    return { location: { lat: parseFloat(lat), lng: parseFloat(lng) } };
  });
  return {
      origin: convertToLatLng(origin),
      destination: convertToLatLng(destination),
      waypoints: waypoints
  };
}

const convertToLatLng = (latLngString) => {
  const [lat, lng] = latLngString.split(',').map(Number);
  return { lat, lng };
};

export { SweetAlert, showConfirmDialog, getUserIdFromLC, translate, extractCoordinates };
