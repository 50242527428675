import React from 'react';
import './ProgressBar.css'; // Import your CSS file

const ProgressBar = ({ value, maxValue }) => {
    const getColorForPercentage = (percentage) => {
        if (percentage <= 33) {
            return '#ff0000'; // Red for low levels
        } else if (percentage <= 66) {
            return '#ffcc00'; // Yellow for mid levels
        } else {
            return '#4caf50'; // Green for high levels
        }
    };
    const percentage = (value / maxValue) * 100;
    const color = getColorForPercentage(percentage);

  return (
    <div className="progress-bar-wrapper">
        <div className="progress-bar-container">
            <div
                className="progress-bar-fill"
                style={{ width: `${percentage}%`, backgroundColor: color }}
            >
        </div>
        </div>
        <div className="progress-bar-text">{`${percentage.toFixed(0)}% Left`}</div>
    </div>
  );
};

export default ProgressBar;
