import React, { useState, useEffect } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Sidebar, Menu, SubMenu, MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import Logo from "../../assets/images/logo.png";
import Home from "../../assets/images/icons/home.svg";
import Vehicles from "../../assets/images/icons/vehicles.svg";
import Reports from "../../assets/images/icons/reports.svg";
import Orders from "../../assets/images/icons/orders.svg";
import Plans from "../../assets/images/icons/plans.svg";
import Faqs from "../../assets/images/icons/faqs.svg";
import EChalan from "../../assets/images/icons/E-chalan.svg";
import TripCalculator from '../../assets/images/icons/TripCalculator.svg'
import Documents from '../../assets/images/icons/documents.svg'
import Support from "../../assets/images/icons/support.svg";
import gpsPlan from "../../assets/images/icons/gps_plan.svg";
import gpsFuelPlan from "../../assets/images/icons/gps_fuel_plan.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import { collapseHeader, unCollapseHeader } from "../../redux/actions/fucntionActions";
import { translate } from "../../helpers/helper";
import Translate from "../../components/Translate";
import { SidebarMenuHeader } from "react-bootstrap-sidebar-menu";
import { FaRoute } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { PiUsersThreeLight } from "react-icons/pi";
import { CiRoute } from "react-icons/ci";
import { BsFuelPump } from "react-icons/bs";
import { CompanyRoutesConfig } from "../../routes/companyRoutes";


const Header = (props) => {
  const location = useLocation();
  const dispatchAction = useDispatch();
  const [width, setWidth] = useState("");
  const [display, setDisplay] = useState("");
  const [breakpoint, setbreakpoint] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [language, setLanguage] = useState("");
  const [className, setClassName] = useState("sidebar");
  const [isSidebar, setIsSideBar] = useState(false)
  const callback = (state)=> state;
  const states = useSelector(callback);
  const ftmUserData = localStorage.getItem('ftmUserData');
  // const access = ftmUserData.access
  const access = ["Dashboard","User","Vehicles","FleetReports","PricingPlan"]
  const accessedRoutes = CompanyRoutesConfig.filter(route =>access.includes(route.name));

  useEffect(() => {
    setWidth(window.innerWidth)
  })
  const handleCollapse = () => {
    setCollapsed(!collapsed);
    if (collapsed) {
      dispatchAction(collapseHeader(true));
      
    } else {
      dispatchAction(unCollapseHeader(false))
    }
  };
  const toggleSidebar = () => {
    console.log("0")
    if(width < 590) {
      console.log("1",width < 590)
      setIsSideBar(!isSidebar);
      setCollapsed(!collapsed);
    } else if(width >= 590 && width < 1090){
      console.log("2",width >= 590 && width < 1090)
      setIsSideBar(!isSidebar)
      setCollapsed(!collapsed);
    } else if (width >= 1091) {
      console.log("3",width >= 1091)
      handleCollapse()
    }
  };
  
  const closeSidebar = () => {
    if (window.innerWidth < 590) {
      setIsSideBar(false);
      setCollapsed(!collapsed);
    } else if (window.innerWidth >= 590 && window.innerWidth < 1090) {
      setIsSideBar(false);
      setCollapsed(!collapsed);
    }
    
  };

  function getSize () {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', getSize)
    if (window.innerWidth < 590) {
      setIsSideBar(false)
      setCollapsed(true)
      setDisplay("")
      setbreakpoint(false)
      setClassName("collapsableSidebar");
    } else if (window.innerWidth >= 590 && window.innerWidth < 1090){
      setIsSideBar(false)
      setCollapsed(true)
      setDisplay("")
      setbreakpoint(false)
      setClassName("collapsableSidebar");
    } else {
      setIsSideBar(true)
      setCollapsed(false)
      setDisplay("display")
      setbreakpoint(true)
      setClassName("sidebar");
    }
    return () => {
        window.removeEventListener('resize', getSize);
    }
  }, [window.innerWidth]);

  useEffect (()=> {
    if (localStorage.getItem('language') == null) {
      setLanguage('english');
    } else {
      setLanguage(localStorage.getItem('language'))
    }
  }, [states.language.language])

  useEffect (()=> {
    if (states?.collapsable?.collapsed == true) {
      setClassName("collapsableSidebar")
    } else {
      setClassName('sidebar')
    }
  }, [states.collapsable.collapsed])

  const showSubMenu = access.includes("PricingPlan");

  function BreakPoint () {
    setbreakpoint(!breakpoint);
  }
  // onClick={() =>{ handleCollapse(!collapsed);}}
  return (
    <div className={className}>
      <div className="sidebar-container w-100">
      <div className="logo-parent">
        <button className ="burger-button" onClick={toggleSidebar}>
        <RxHamburgerMenu className="cursor-pointer burger-menu"/>
        </button>
        <h1 className={`${window.innerWidth<1080 ? 'logo-collapsed' : 'logo'}`}>
          <img src={Logo} width={"154px"} height={"50px"} />
        </h1>
      </div>
      {isSidebar && (<Sidebar 
        collapsed={collapsed} 
        backgroundColor="#ffffff" 
        rootStyles={{
          overflowY: "visible",
          position: 'fixed',
          height: '100vh',
          top: 0,
          width: '20%',
          marginTop: "60px",
          transition: 'width 0.4s ease-in-out',
        }}
      >
        <Menu>
           <MenuItem
            active={location.pathname  === "/dashboard"}
            component={<Link to="/dashboard" />}
            icon={<SVG src={Home} width={"25px"} height={"25px"} />}
            onClick={closeSidebar}
          >
            <Translate>dashboard</Translate>
          </MenuItem>
          <MenuItem
            active={location.pathname  === "/vehicles"}
            component={<Link to="vehicles" />}
            icon={<SVG src={Vehicles} width={"25px"} height={"25px"} />}
            onClick={closeSidebar}
          >
            <Translate>myVehicles</Translate>
          </MenuItem>
          {/* <MenuItem
            active={location.pathname  === "/geofence"}
            component={<Link to="/geofence" />}
            icon={<SVG src={Vehicles} width={"25px"} height={"25px"} />}
          >
            <Translate>myVehicles</Translate>
          </MenuItem> */}
          <MenuItem
            active={location.pathname  === "/fleet-reports"}
            component={<Link to="/fleet-reports" />}
            icon={<SVG src={Reports} width={"25px"} height={"25px"} />}
            onClick={closeSidebar}
          >
            <Translate>fleetReport</Translate>
          </MenuItem>
          <MenuItem
            className="fuel-pump"
            active={location.pathname  === "/fuel-theft"}
            component={<Link to="/fuel-theft" />}
            icon = {<BsFuelPump size = "22px" color="#626C70" />}
            onClick={closeSidebar}
          >
            <Translate>fuelanalytics</Translate>
          </MenuItem>
          <MenuItem
            active={location.pathname  === "/orders"}
            component={<Link to="/orders" />}
            icon={<SVG src={Orders} width={"25px"} height={"25px"} />}
            onClick={closeSidebar}
          >
            <Translate>myOrders</Translate>
          </MenuItem>
          <SubMenu
            label={<Translate>pricingPlans</Translate>}
            icon={<SVG src={Plans} width={"25px"} height={"25px"} />}
            className="sub-menu"
          >
            <MenuItem
              label={<Translate>buyNewGPS</Translate>}
              active={location.pathname  === "/plans/buy-gps"}
              icon={<SVG src={gpsPlan} width={"25px"} height={"25px"} />}
              component={<Link to="/plans/buy-gps" />}
              onClick={closeSidebar}
            >
              <Translate>buyNewGPS</Translate>
            </MenuItem>
            <MenuItem 
              label={<Translate>buyNewFuelSensorGPS</Translate>}
              active={location.pathname  === "/plans/buy-gps-fuel"}
              icon={<SVG src={gpsFuelPlan} width={"25px"} height={"25px"} />}
              component={<Link to="/plans/buy-gps-fuel" />}
              onClick={closeSidebar}
            >
              <Translate>buyNewFuelSensorGPS</Translate>
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="desktop-view"
            active={location.pathname  === "/documents"}
            component={<Link to="/documents" />}
            icon={<SVG src={Documents} width={"23px"} height={"23px"} />}
            onClick={closeSidebar}
          >
            <Translate>documents</Translate>
          </MenuItem>
          <MenuItem
            active={location.pathname  === "/faqs"}
            component={<Link to="/faqs" />}
            icon={<SVG src={Faqs} width={"25px"} height={"25px"} />}
            onClick={closeSidebar}
          >
            <Translate>faqs</Translate>
          </MenuItem>
          {/* <MenuItem
            active={location.pathname  === "/e-challan"}
            component={<Link to="/e-challan" />}
            icon={<SVG src={EChalan} width={"25px"} height={"25px"} />}
          >
            <Translate>echallan</Translate>
          </MenuItem> */}
          <MenuItem
            className="fuel-pump"
            active={location.pathname  === "/trip-calculator"}
            component={<Link to="/trip-calculator" />}
            icon = {<CiRoute size = "25px"/>}
            onClick={closeSidebar}
            >
           <Translate>tripCalculator</Translate> 
          </MenuItem> 
           <MenuItem
            active={location.pathname  === "/support"}
            component={<Link to="/support" />}
            icon={<SVG src={Support} width={"25px"} height={"25px"} />}
            onClick={closeSidebar}
          >
            <Translate>support</Translate>
          </MenuItem>
       </Menu>
      </Sidebar>)}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => (
{
  collapsed: state.collapsed
});

const mapDispatchToProps = (dispatch) => (
{
  handleCollapse: () => dispatch({ type: "collapsed" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
{/* <Menu>
        {accessedRoutes.map((route,index) => (
          <MenuItem
          key={index}
          active={location.pathname  === route.path}
          component={<Link to={route.path} />}
          icon={route.src === "" ? route.icon : <SVG src={route.src} width="25px" height="25px" />}                
          >
          {route.name} */}
          {/* <Translate>{route.name}</Translate> */}
        // </MenuItem>
        // ))}
        //     {showSubMenu  && 
        //      <SubMenu
        //     label={<Translate>pricingPlans</Translate>}
        //     icon={<SVG src={Plans} width={"25px"} height={"25px"} />}
        //     className="sub-menu"
        //   >
        //     <MenuItem
        //       label={<Translate>buyNewGPS</Translate>}
        //       active={location.pathname  === "/plans/buy-gps"}
        //       icon={<SVG src={gpsPlan} width={"25px"} height={"25px"} />}
        //       component={<Link to="/plans/buy-gps" />}
        //     >
        //       <Translate>buyNewGPS</Translate>
        //     </MenuItem>
        //     <MenuItem 
        //       label={<Translate>buyNewFuelSensorGPS</Translate>}
        //       active={location.pathname  === "/plans/buy-gps-fuel"}
        //       icon={<SVG src={gpsFuelPlan} width={"25px"} height={"25px"} />}
        //       component={<Link to="/plans/buy-gps-fuel" />}
        //     >
        //       <Translate>buyNewFuelSensorGPS</Translate>
        //     </MenuItem>
        //   </SubMenu>
        //   }
        // </Menu>

