export const collapseHeader = (collapse) => {
    return {
      type: "collapsed",
      payload: {
        collpased: collapse
      },
    };
  };

export const unCollapseHeader = (collapse) => {
    return {
        type: 'uncollapsed',
        payload: {
            collpased: collapse
        }
    }
}