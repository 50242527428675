import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Circle,Polygon, DrawingManager, GoogleMap, useLoadScript } from '@react-google-maps/api';
import "./indexGeo.css"
import {SweetAlert} from "../../helpers/helper"
import AutocompleteInput from "../../components/AutocompleteInput";
import { deleteApi, get, post } from "../../components/api";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const libraries = ['places','drawing'];


const Geofence = () => {
  const { state } = useLocation();
  const [geofenceName, setGeofenceName] = useState("")
  const [geofenceType, setGeofenceType] = useState("")
  const [location, setLocation] = useState("")
  const [paths, setPaths] = useState([])
  const [sendPaths , setSendPaths] = useState({})
  const [radius, setRadius] = useState("")
  const [searchType, setSearchType] = useState("place")
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [states, setStates] = useState({drawingMode : ""})
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries,
      });
      const navigate = useNavigate()
      // 'AIzaSyAwxOE1HVNc1gj4FjP5kqqIJZodveNwOFc'
      const handleClear = () => {
        setPaths([])
        setGeofenceName("")
        setGeofenceType("")
        setLocation("")
        setRadius('')
        setLatitude(0)
        setLongitude(0)
        setStates({drawingMode : ""})
      }
      useEffect(() => {
        if(searchType === "coordinates"){
          if(state.length = 2){
            setLatitude(state[0])
            setLongitude(state[1])
          }
        }
      },[searchType]);

      const mapContainerStyle = {
        width: window.innerWidth < 850 ? "100%" :'600px',
        height: '450px',
      };
      const mapOptions = {
        mapTypeId: "roadmap",
        zoomControl: true,
        streetViewControl: false, 
        fullscreenControl: false, 
        mapTypeControl: false, 
        trafficControlOptions: {
          mapTypeIds: ['roadmap', 'satellite'],
          style: window.google.maps.TrafficStyle
        }
      }

      const options = {
        drawingControl : true,
        drawingControlOptions:{
          position: "none",
          drawingMode:[geofenceType]
        },
        polygonOptions:{
          fillColor:'#2196F3',
          strokeColor:'#2196F3',
          fillOpacity:0.5,
          strokeWeight:2,
          Clickable:true,
          // editable:true,
          draggable:false,
          zindex:1,
        },

        circleOptions:{
          fillColor:'#2196F3',
          strokeColor:'#2196F3',
          fillOpacity:0.5,
          strokeWeight:2,
          Clickable:true,
          editable:true,
          draggable:false,
          zindex:1,
        }
    }
      const onPolygonComplete = useCallback(
        function onPolygonComplete(poly) {
          const polyArray = poly.getPath().getArray();
          console.log("polyArray---",polyArray)
          let paths = [];
          let sendPaths = [];
          polyArray.forEach((path) => {
            paths.push({lat:path.lat(),lng:path.lng()})
          });
          polyArray.forEach((path) => {
            sendPaths.push([path.lng(),path.lat()])
          });
          const finalPaths = [...paths,paths[0]]
          const finalsendPaths ={coordinates : [...sendPaths,sendPaths[0]], center : [], radius : 0 }
          setPaths(finalPaths);
          setSendPaths(finalsendPaths)
          poly.setMap(null)
        },
        []
      );

      const onCircleComplete = useCallback((circle) => {
        const center = circle.getCenter();
        console.log(center)
        const radius = Math.floor(circle.getRadius());
        setPaths([{ lat: center.lat(), lng: center.lng(), radius }]);
        setSendPaths({ center : [center.lng(),center.lat()], radius : radius ,coordinates:[]});
        circle.setMap(null);
        // setGeofenceType('');
        // setStates({ drawingMode: '' });
        setRadius(radius)
      },[]
    )
      // const polygonRef = useRef(null);
      // const listenerRef = useRef([]);

      // const onEdit = useCallback(() => {
      //   if(polygonRef.current){
      //     console.log("polygonRef---",polygonRef)
      //     const nextPath = polygonRef.current.getPath().getArray().map(latlng => {
      //       return {lat:latlng.lat(), lng:latlng.lng()}
      //     })
      //     console.log("nextPath---",nextPath)
      //     setPaths(nextPath)
      //   }
      // },[])

      // const onLoad = useCallback(
      //   polygon => {
      //     polygonRef.current = polygon
      //     const path = polygon.getPath()
      //     listenerRef.current.push(
      //       path.addListener("set_at", onEdit),
      //       path.addListener("insert_at", onEdit),
      //       path.addListener("remove_at", onEdit)
      //     )
      //     console.log(paths)
      // },[onEdit])

      // const onUnmount = useCallback(() => {
      //   listenerRef.current.forEach(lis=> lis.remove())
      //   listenerRef.current = null
      // },[])
      // Handle change event
    const handleChange = (event) => {
      setPaths([])
      setGeofenceType(event.target.value);
      setStates({drawingMode : event.target.value})
      };

      const handleRadiusChange = (event) => {
        const newRadius = Math.floor(parseFloat(event.target.value));
        setRadius(newRadius);
        if (paths?.length > 0) {
          setPaths([{ ...paths[0], radius: newRadius }]);
          setSendPaths({ center : sendPaths.center, radius : newRadius });
          // setSendPaths( sendPaths.radius = newRadius );
        }
      };

      const toggleChange = () => {
        if(searchType === 'place') {
          setSearchType('coordinates')
        }else if(searchType === 'coordinates') {
          setSearchType('place')
        }
      }

      const handlePlaceSelected = (place, inputIdentifier) => {
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
        setLocation(place.formatted_address)
      };

      const handleSubmit = async (e) => {
        e.preventDefault()
        if(geofenceName?.length > 4 ) {
          if (searchType === 'place' && location?.length > 4) {
            // /add-geofence
            try {
              const ftmUserData = JSON.parse(localStorage.getItem('ftmUserData'));
              const companyid = ftmUserData.id;
                const apiResponse = await post("admin/add-geofence", {
                geofencename:geofenceName,
                geofencetype:geofenceType,
                searchtype:searchType,
                paths:sendPaths,
                latitude:latitude,
                longitude:longitude,
                location:location,
                companyid:companyid
              });
              if (apiResponse?.status === 200) {
                SweetAlert(apiResponse?.message, false);
                handleClear()
                navigate("/geofence")
              } else if (apiResponse?.status === 201) {
                SweetAlert(apiResponse?.message, true);
              }
            } catch (error) {
              console.log(error);
            }

          }else if (searchType === 'coordinates' && latitude && longitude){
            try {
              const ftmUserData = JSON.parse(localStorage.getItem('ftmUserData'));
              const companyid = ftmUserData.id;
              const apiResponse = await post("admin/add-geofence", {
                geofencename:geofenceName,
                geofencetype:geofenceType,
                searchtype:searchType,
                paths:paths,
                latitude:latitude,
                longitude:longitude,
                location:location,
                companyid:companyid
              });
              if (apiResponse?.status === 200) {
                SweetAlert(apiResponse?.message, false);
                handleClear()
                navigate("/geofence")
              } else if (apiResponse?.status === 201) {
                SweetAlert(apiResponse?.message, true);
              }
            } catch (error) {
              console.log(error);
            }
          }
          else{
            SweetAlert(` All fields are required`, true);
          }

        }else{
          SweetAlert("geofence Name has atleast four letters", true)
        }

      }
      

      

      if (loadError) {
        return <div>Error loading maps</div>;
      }
    
      if (!isLoaded) {
        return <div>Loading maps</div>;
      }
    
  return (
         <div className="Geofence-main-container">
        <div className="form-container card p-3">
        <h6 className='text-left p-3'>Create Geofence</h6>
        <div className="containerGeofence">
        <label className="required custom-lable">Place</label>
          <label className='switch'>
            <input type="checkbox" value ={searchType} onChange={toggleChange}/>
            <span className='slider'/>
          </label>
        <label className="required custom-lable">Coordinates</label>
        </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group text-left">
              <label className="required custom-lable">Geofence Name</label>
              <input
                placeholder="Geofence Name"
                type="text"
                name="geofenceName"
                className="form-control mb-1"
                autoComplete="off"
                value={geofenceName}
                onChange={(e) => setGeofenceName(e.target.value)}
              />
            </div>
  
            {searchType === 'place' &&
              <div className="text-left form-group">
               <label className="required custom-lable">Location</label>
                <AutocompleteInput 
                      name="Location"
                      value={location}
                      onPlaceSelected={handlePlaceSelected}  
                      className="userInput" 
                    />
              </div>
            }
            {searchType === 'coordinates' &&
              <div className="text-left form-group">
               <label className="required custom-lable">Latitude</label>
                <input
                  placeholder="Latitude"
                  type="text"
                  name="Latitude"
                  className="form-control mb-3"
                  autoComplete="off"
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                />
               <label className="required custom-lable">Longitude</label>
                <input
                  placeholder="Longitude"
                  type="text"
                  name="Longitude"
                  className="form-control mb-3"
                  autoComplete="off"
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </div>
            }
            
  
            <div className="text-left form-group">
              <p className='custom-lable mt-1 mb-2'>Choose Geometry of Geofence</p>
              <label className="radio-label custom-lable">
                <input
                  type="radio"
                  name="myRadio"
                  value="circle"
                  checked={geofenceType === 'circle'}
                  onChange={handleChange}
                />
                Circle
              </label>
              <label className="radio-label custom-lable">
                <input
                  type="radio"
                  name="myRadio"
                  value="polygon"
                  checked={geofenceType === 'polygon'}
                  onChange={handleChange}
                />
                Polygon
              </label>
            </div>

            {paths?.length === 1 && 
            <div className='text-left form-group'>
              <input type="number" name='radius' value={radius} placeholder={radius} onChange={handleRadiusChange} className='form-control'/>
            </div>
            }
            <div className='card-footer'>
            <div className="button-group">
              <button type="submit" className="btn-geo-create">Create</button>
              <button type="button" className="btn-geo-secondry" onClick={handleClear}>Cancel</button>
            </div>
            </div>
          </form>
        </div>
  
        <div className="map-container border-1">
          <GoogleMap
            zoom={13}
            mapContainerStyle={mapContainerStyle}
            center={{ lat: paths[0]?.lat || latitude || 22.9, lng: paths[0]?.lng || longitude || 72.5 }}
            options={mapOptions}
          >
            {paths?.length === 0 ? 
            <DrawingManager
            drawingMode={states.drawingMode}
            options={options}
            editable
            onPolygonComplete={onPolygonComplete}
            onCircleComplete={onCircleComplete}
            // onMouseUp={onEdit}
            // onDragEnd={onEdit}
          /> 
          : paths?.length ===1 ? 
          <Circle
          center={{ lat: paths[0]?.lat, lng: paths[0]?.lng }}
          radius={paths[0]?.radius}
          options={{
            fillColor: '#2496F3',
            strokeColor: '#2496F3',
            fillOpacity: 0.5,
            strokeWeight: 2,
          }}
          // editable
        /> 
        : <Polygon
          options={{
            fillColor: '#2496F3',
            strokeColor: '#2496F3',
            fillOpacity: 0.5,
            strokeWeight: 2,
          }}
          // editable
          path={paths}
          // onLoad={onLoad}
          // onUnmount={onUnmount}
        />
        }   
        </GoogleMap>
        </div>
        </div>
        
      );
};

export default Geofence;