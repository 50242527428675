import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ options, series, type, height }) => {
    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type={type} height={height} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ApexChart;
