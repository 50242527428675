const initialState = {language: {language: "english"}};

const getLanguage = (state = initialState, action) => {
    switch (action.type) {
        case "english":
            return {language: "english"}
        case "hindi":
            return {language: "hindi"}
        default:
            return state
    }
}

export default getLanguage;