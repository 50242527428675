import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import useRazorpay from 'react-razorpay';
import { post, get } from '../../components/api';
import ModalPopup from "../../components/ModalPopup";
import Basic from "../../assets/images/plans/Basic.svg";
import Premium from "../../assets/images/plans/Premium.svg";
import Standard from "../../assets/images/plans/Standard.svg";
import ArrowRight from "../../assets/images/plans/ArrowRight.svg";
import {SweetAlert, showConfirmDialog} from '../../helpers/helper';
import rtoArrayList from "../../assets/rtoList";
import Translate from "../../components/Translate";

// vehicle add validation
const validationSchema = Yup.object().shape({
    vehiclesData: Yup.array().of(
      Yup.object().shape({
        vehicleNo: Yup.string().required("Vehicle no is required")
        .matches(/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/, "Please enter a valid vehicle number format.")
        .test("match-rtostatelist", "No such state code found", (value)=> {
          if (value) {
            return rtoArrayList.some(item => item === value.substring(0,4))
          } else {
            return true;
          }
        }),
        engineType: Yup.string().required("Engine type is required"),
      })
    ),
});

const BuyGpsFuel = () => {
  let navigate = useNavigate();
  const initialValues = {
    vehiclesData: [
        {
          vehicleNo: "",
          engineType: ""
        },
    ],
  };
  const [Razorpay, initialize] = useRazorpay();
  const [selectedPlan, setSelectedPlan] = useState('');
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [engineTypeData, setEngineTypeData] = useState([]);
  const fetchPlanData = async () => {
    try {
      let params= {planType: 'GPS_FUEL'}
      const response = await post("/admin/get-planlist", params);
      if (response.status == 200){
        setPlans(response.data);
      } else {
        setPlans([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEngineTypeData = async () => {
    try {
      const response = await get("/admin/truck-engine-type");
      if (response.status == 200){
        setEngineTypeData(response.data);
      } else {
        setEngineTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPlanData();
    fetchEngineTypeData();
  }, []);

  // form
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      let jsonData = {
        planId: selectedPlan,
        vehicles: values.vehiclesData
      }
      handlePayment(jsonData);
    },
  });

  // show modal
  const handleShowModal = (planId) => {
    setSelectedPlan(planId);
    setShowVehicleModal(true);
  };

  // close modal
  const handleCloseModal = () => {
    setSelectedPlan('');
    const clearedVehiclesData = initialValues.vehiclesData.map(() => ({
      vehicleNo: '',
      engineType: ''
    }));
    formik.setValues({ ...formik.values, vehiclesData: clearedVehiclesData });
    setShowVehicleModal(false);
  };

  const openDeleteDialog = async (id) => {
    const confirmed = await showConfirmDialog(
      "Delete!",
      "Are you sure you want to delete vehicle?"
    );
    if (confirmed) {
      let contentArray = formik.values.vehiclesData;
      contentArray.splice(id, 1);
      formik.setFieldValue('vehiclesData', contentArray)
    }
  };

  // Razor pay payment 
  const handlePayment = async (params) => {
    try {
      // Create order on your backend and retrieve the order details
      const order = await createOrder(params);
      const options = {
        key: "rzp_live_jHwzWThKO7OuEn", // Your Razorpay Key ID
        amount: order.amount, // Order amount in paise (e.g., 50000 for ₹500.00)
        currency: order.currency,
        name: "FleetStakes Pvt.LTD",
        description: "Test Transaction",
        image: "http://localhost:3000/static/media/logo.91ed4b160f822935a22b.png",
        order_id: order.orderId, // Pass the order ID obtained from your backend
        prefill: {
          name: "Sedhal Soni",
          email: "test@example.com",
          contact: "8980782616",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        handler: function (response) {
          // Handle successful payment
          console.log(response.razorpay_payment_id);
          console.log(response.razorpay_order_id);
          console.log(response.razorpay_signature);
          SweetAlert("Plan purchase successfully.");
          setTimeout(() => {
            navigate('/orders');
          }, 2000);
        },
        modal: {
          ondismiss: function() {
            // Handle the case when the user closes the payment modal without completing the payment
            console.log("Payment modal has been dismissed");
          }
        }
      };
      const rzp1 = new Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  async function createOrder (params) {
    try {
      const response = await post("/admin/create-order", params);
      if (response.status == 200) {
        return response.data;
      } else {
        SweetAlert(response.message, true);
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <div className="row p-2 m-0 h-100 d-flex justify-content-center align-items-center">
      {plans.length > 0 &&
        plans.map((plan, pIndex) => {
          return (
            <div className="col-md-4 col-lg-4 col-xs-12 mb-4 mb-md-0 mb-lg-0" key={`plan-${pIndex}`}>
              <div className="card plan-card">
                <div className="card-body">
                  <div className="mb-5">
                    <SVG
                      src={pIndex == 0 ? Basic : pIndex == 1 ? Standard : Premium}
                      className="main-icon"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                    />
                    <h6 className="plan-title mt-3">{plan.name}</h6>
                    <p className="plan-desc mt-3"><Translate>buyNewGpsFuelMessage</Translate></p>
                    <p className="plan-price mb-1">{`₹${plan.discountedPrice}`}</p>
                    <p className="plan-year">₹{plan.perMonth} / <Translate>perMonth</Translate></p>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="btn btn-outline-primary custom-btn d-flex align-items-center justify-content-evenly"
                        onClick={() => handleShowModal(plan._id)}
                      >
                        <Translate>getStarted</Translate> <SVG src={ArrowRight} />{" "}
                      </Button>{" "}
                    </div>
                  </div>
                  {/* <hr />
                  <div className="d-flex flex-column align-items-start mt-4 plan-detail-text">
                    {plan.details.length > 0 &&
                      plan.details.map((detail, i) => (
                        <div className="d-flex justify-content-center align-items-center mt-1" key={`detail-${i}`}>
                          <SVG
                            src={CheckCircle}
                            alt=""
                            width={"25px"}
                            height={"25px"}
                          />
                          <p className="mb-1 ml-2 plan-details">{detail}</p>
                        </div>
                      ))}
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
      {showVehicleModal && formik && <ModalPopup
        className="add-vehicle-modal"
        show={showVehicleModal}
        onHide={handleCloseModal}
        title="Add Vehicle"
      >
        <form onSubmit={formik.handleSubmit}>
          {formik.values.vehiclesData.map((vehicle, index) => (
            <div className="row mb-4" key={`vehicle-${index}`}>
              <div className="col-md-12 d-flex justify-content-between align-items-center">
                <label
                  htmlFor={`vehiclesData.${index}.vehicleNo`}
                  className="required custom-form-label mb-1"
                >
                  <Translate>vehicle</Translate> {index + 1}
                </label>
                {formik.values.vehiclesData.length > 1 && <OverlayTrigger
                  overlay={
                    <Tooltip id={`products-delete-tooltip-${index}`}>
                      Delete
                    </Tooltip>
                  }
                >
                  <a className="btn btn-icon delete-btn btn-hover-danger btn-sm" onClick={() => {
                      openDeleteDialog(index)
                    }}>
                    <TrashFill size={16} />
                  </a>
                </OverlayTrigger>}
              </div>
              <div className="col-md-12 mb-7">
                <input
                  type="text"
                  id={`vehiclesData.${index}.vehicleNo`}
                  name={`vehiclesData.${index}.vehicleNo`}
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={vehicle.vehicleNo} // Accessing value directly from the vehicle object
                />
                {formik.touched.vehiclesData && formik.touched.vehiclesData[index] && formik.errors.vehiclesData && formik.errors.vehiclesData[index] && (
                  <div><p className="mb-0 field-error">{formik.errors.vehiclesData[index].vehicleNo}</p></div>
                )}
              </div>
              <div className="col-md-12 d-flex justify-content-between align-items-center mt-2">
                <label
                  htmlFor={`vehiclesData.${index}.vehicleNo`}
                  className="required custom-form-label mb-1"
                >
                  <Translate>engineType</Translate>
                </label>
              </div>
              <div className="col-md-12 mb-7">
                <select
                  id={`vehiclesData.${index}.engineType`}
                  name={`vehiclesData.${index}.engineType`}
                  className="form-select form-control form-control-solid mb-3 mb-lg-0"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={vehicle.engineType}
                >
                  <option value=""><Translate>pleaseSelectEngineType</Translate></option>
                  {engineTypeData.length > 0 && engineTypeData.map((enginetype, i)=> (
                    <option value={enginetype._id}>{enginetype.name}</option>
                  ))}
                </select>
                {formik.touched.vehiclesData && formik.touched.vehiclesData[index] && formik.errors.vehiclesData && formik.errors.vehiclesData[index] && (
                  <div><p className="mb-0 field-error">{formik.errors.vehiclesData[index].engineType}</p></div>
                )}
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-end mt-2">
            <a
              href="javascript:void(0)"
              className="plan-add-vehicle"
              onClick={() =>
                formik.setFieldValue("vehiclesData", [
                  ...formik.values.vehiclesData,
                  { vehicleNo: "", engineType: "" },
                ])
              }
            >
              + <Translate>addVehicle</Translate>
            </a>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="submit"
              className="btn pay-now-btn"
            >
              <Translate>payNow</Translate>
            </button>
          </div>
        </form>
      </ModalPopup>}
    </div>
  );
};

export default BuyGpsFuel;
