import React, { useState, useEffect } from "react";
import { MdShareLocation } from "react-icons/md";
import fuelIcon from "../assets/vehicles/fuelIcon.png";
import emergencyServiceIcon from "../assets/vehicles/emergencyServiceIcon.png";
import ignitionIcon from "../assets/vehicles/ignitionIcon.png";
import mapIcon from "../assets/vehicles/mapIcon.png";
import Translate from "./Translate";
import { Display } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const VehicleElement = ({
  ignitionStatus,
  speed,
  address,
  totalKmTravelled,
  fuelLevel,
  routeHistoryKm,
  vehicleNo,
  coordinates,
  isFuel,
  ignition,
  lastRecordTime
}) => {
  const [ignitionStatusClass, setIgnitionStatusClass] = useState("");
  const [ignitionText, setIgnitionText] = useState("");

  const date = new Date(lastRecordTime)
  const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`

  let handleAddressLength = (address) => {
    if (address.length > 121) {
      return String(address).slice(0, 118) + "...";
    } else {
      return address;
    }
  };

  let handleIgnitionStatus = (ignitionStatus) => {
    switch (ignitionStatus) {
      case "idle":
        setIgnitionText("idle");
        setIgnitionStatusClass("vehicleStatusIdle");
        break;
      case "moving":
        setIgnitionText("moving");
        setIgnitionStatusClass("vehicleStatus");
        break;
      case "offline":
        setIgnitionText("offline");
        setIgnitionStatusClass("vehicleStatusStopped");
        break;
      default:
        setIgnitionText("offline");
        setIgnitionStatusClass("vehicleStatusStopped");
        break;
    }
  };

  useEffect(() => {
    handleIgnitionStatus(ignitionStatus);
  }, [ignitionStatus]);

  return (
    <>
      <div className="vehicleComponent">
        <div className="vehicleComponentHeader">
          <p className="vehicleNo">{vehicleNo}</p>
          <div className="vehicleComponentHeaderLeft">
            <Link
              to={"/geofence/create"}
              state={coordinates}
              className="btn-geo"
            >
              <MdShareLocation size={20} />
            </Link>

            <div className={ignitionStatusClass}>
              <p className="vehicleStatusText">
                <Translate>{ignitionText}</Translate>
              </p>
            </div>
          </div>
        </div>
        <p className="vehicleLastRecordTime">
          {formattedDate}
          </p>
        <p className="vehicleAddress">{handleAddressLength(address)}</p>

        <div>
        <p className="vehicleTravelledToday">
          {totalKmTravelled} km <Translate>travelledToday</Translate>
        </p>
        </div>
        

        <hr></hr>

        <div className="vehicleDetailsWrapper">
          <p className="vehicleDetailsHeading">
            <Translate>summary</Translate>
          </p>

          <div className="vehicleDetails">
            <div className="vehicleBoxWrapper">
              <div
                className={`vehicleDetialBox ${!isFuel ? "grayed-out" : ""}`}
              >
                {/* <div className="details">
                  <p className="vehicleDetailKey">
                    <Translate>fuelLevel</Translate>
                  </p> */}
                {/* {isFuel ? (
                  <p className="vehicleDetailValue">{fuelLevel} Ltr</p>
                ) : (
                  <p className="vehicleDetailValue unavailable">
                    notAvailable
                  </p>
                )} */}
                {/* <p className="vehicleDetailValue">{fuelLevel} Ltr</p> */}
                {/* </div>
                <div className="iconPng">
                  <img src={fuelIcon} className="vehicleIcon" />
                </div> */}
                <div className="details">
                  <p className="vehicleDetailKey">
                    <Translate>fuelLevel</Translate>
                  </p>
                  <p className="vehicleDetailValue">
                    {isFuel ? `${fuelLevel} Ltr` : "Not Available"}
                  </p>
                </div>
                <div className="iconPng">
                  <img src={fuelIcon} className="vehicleIcon" />
                </div>
              </div>
              <div className="vehicleDetialBox">
                <div className="iconPng">
                  <img src={mapIcon} className="vehicleIcon" />
                </div>
                <div className="details">
                  <p
                    className="vehicleDetailKey"
                    style={{ textAlign: "right" }}
                  >
                    <Translate>routeHistory</Translate>
                  </p>
                  <p
                    className="vehicleDetailValue"
                    style={{ textAlign: "right" }}
                  >
                    {routeHistoryKm} Kms
                  </p>
                </div>
              </div>
            </div>
            <div className="vehicleBoxWrapper">
              <div className="vehicleDetialBox">
                <div className="details">
                  <p className="vehicleDetailKey">
                    <Translate>ignition</Translate>
                  </p>
                  <p className="vehicleDetailValue">{ignition ? 'ON' : "OFF"}</p>
                </div>
                <div className="iconPng">
                  <img src={ignitionIcon} className="vehicleIcon" />
                </div>
              </div>
              <div className="vehicleDetialBox">
                <div className="iconPng">
                  <img src={emergencyServiceIcon} className="vehicleIcon" />
                </div>
                <div className="details">
                  <p
                    className="vehicleDetailKey"
                    style={{ textAlign: "right" }}
                  >
                    <Translate>emergency</Translate>
                  </p>
                  <p
                    className="vehicleDetailValue"
                    style={{ textAlign: "right" }}
                  >
                    <Translate>services</Translate>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleElement;
