import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import OverSpeed from "../../assets/images/overspeed.png";
import { post } from "../../components/api";
import { SweetAlert, getUserIdFromLC } from "../../helpers/helper";
import Modal from 'react-bootstrap/Modal'

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required('Current password is required'),
    newPassword: Yup.string()
        .required('New password is required')
        .min(8, 'New password must be at least 8 characters long'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords not match')
        .required('Confirm password is required'),
});

const Setting = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [showOverSpeedModal, setShowOverSpeedModal] = useState(false);

    const [ignitionOnOff, setIgnitionOnOff] = useState(false)
    const [lowFuel, setLowFuel] = useState(false)
    const [overSpeed, setOverSpeed] = useState(false);
    const [overSpeedLimitDefaultCheck, setOverSpeedLimitDefaultCheck] = useState(false);
    const [overSpeedDefaultValue, setOverSpeedDefaultValue] = useState(40);
    const [disableOverSpeedValue, setDisableOverSpeedValue] = useState(false);
    const [suddenFuelDrop, setSuddenFuelDrop] = useState(false)

    const initialValues = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        // vehicle notifications
        vehicleNotification: false
    };
    // form
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            const updatePass = await post('/admin/account-settings/change-password', {
                comapnyId: getUserIdFromLC(),
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
            });

            if (updatePass.status == 200) {
                SweetAlert("Password Changed Successfully", false);
                formik.handleReset();
            } else {
                SweetAlert("Unable to change the password, Try again", true);
                formik.handleReset()
            }
        },
    });

    const togglePasswordVisibility = (type) => {
        if (type == 'current') {
            setPasswordVisible(!passwordVisible);
        } else if (type == 'new') {
            setNewPasswordVisible(!newPasswordVisible)
        } else if ('confirm') {
            setConfirmPasswordVisible(!confirmPasswordVisible)
        }
    };

    const handleIgnitionOnOff = async (ignitionStatus) => {
        const updateIgnitionOnOff = await post("/admin/account-settings/notification", { comapnyId: getUserIdFromLC(), ignationAlertStatus: ignitionStatus })
    }

    const handleCloseOverSpeedModal = () => {
        setShowOverSpeedModal(false);
        setOverSpeed(false);
    }

    const handleOverSpeedModal = (isChecked => {
        setOverSpeed(isChecked);
        if (isChecked == true) {
            setShowOverSpeedModal(true);
        }
    })

    const handleOverSpeedDefaultValue = (isChecked => {
        if (isChecked) {
            setOverSpeedDefaultValue(40);
            setDisableOverSpeedValue(true)
            setOverSpeedLimitDefaultCheck(isChecked)
        } else {
            setDisableOverSpeedValue(false)
            setOverSpeedLimitDefaultCheck(isChecked)
        }
    });

    const handleOverSpeedBody = () => {
        return {
            "companyId": getUserIdFromLC(),
            "overSpeedAlertStatus": true,
            "speedLimit": overSpeedDefaultValue
        }
    }

    const handleSettingsBody = ()=> {
        let body = {
        "ignationAlertStatus": ignitionOnOff,
        "companyId": getUserIdFromLC()
    }
        

        if (overSpeed) {
            body.overSpeedAlertStatus = overSpeed;
            body.speedLimit = overSpeedDefaultValue;
        } else {
            body.overSpeedAlertStatus = overSpeed;
        }

        return body;
    }

    const handleOverSpeedApiCall = async () => {
        const overSpeedCall = await post("/admin/account-settings/notification", handleOverSpeedBody());
        if (overSpeedCall.status == 200) {
            SweetAlert("Settings updated successfully", false);
            handleCloseOverSpeedModal();
            setOverSpeedLimitDefaultCheck(false);
            setOverSpeedDefaultValue(40);
            setDisableOverSpeedValue(false);
            await fetchState()
        } else {
            SweetAlert("Unable to update the setting, Try again", true);
            handleCloseOverSpeedModal();
            setOverSpeedLimitDefaultCheck(false);
            setOverSpeedDefaultValue(40);
            setDisableOverSpeedValue(false);
            setOverSpeed(false)
        }
    }

    const fetchState = async () => {
        const getUpdatedState = await post("/getNotificationSetting", {});
        if (getUpdatedState.status == 200) {
            let apiResponse = getUpdatedState.data;
            if (apiResponse.overSpeedAlertStatus) {
                setOverSpeed(apiResponse.overSpeedAlertStatus);
                setOverSpeedDefaultValue(apiResponse.speedLimit);
                setIgnitionOnOff(apiResponse.ignationAlertStatus)
            } else {
                setOverSpeed(apiResponse.overSpeedAlertStatus);
                setIgnitionOnOff(apiResponse.ignationAlertStatus)
            }
        }
    }

    const handleSettingsApiCall = async ()=> {
        const apiResponse = await post('/admin/account-settings/notification', handleSettingsBody());

        if (apiResponse.status == 200) {
            SweetAlert("Settings updated successfully", false);
            fetchState();
        } else {
            SweetAlert("Unable to update the notification setting", true);
            fetchState();
        }
    }

    useEffect(() => {
        fetchState()
    }, [])

    return (
        <>
            {showOverSpeedModal ?
                <>
                    <Modal show={showOverSpeedModal} centered backdrop="static" onHide={handleCloseOverSpeedModal}>
                        <Modal.Header closeButton>
                            <Modal.Title className="reportModalTitle">Add Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row'>
                                <div className="overspeed-section mt-2">
                                    <div className="text-left">
                                        <label
                                            htmlFor={`overspeed`}
                                            className="required n-title"
                                        >
                                            Enter Speed Limit
                                        </label>
                                        <input
                                            type="text"
                                            id={`overspeed`}
                                            name={`overspeed`}
                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                            value={overSpeedDefaultValue}
                                            disabled={disableOverSpeedValue}
                                            onChange={(e) => setOverSpeedDefaultValue(e.target.value)}
                                        />
                                    </div>
                                    <div className="text-left d-flex align-items-center mt-2">
                                        <input type="checkbox" checked={overSpeedLimitDefaultCheck} onChange={(e) => handleOverSpeedDefaultValue(e.target.checked)} id="flexCheckOverSpeedDefault" className="custom-checkbox" />
                                        <p className="mb-0 n-title">Set this Limit as Default</p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='documentModalBtnWrapper'>
                                <div className='documentModalCancelBtn'>
                                    <a onClick={() => handleCloseOverSpeedModal()}> Cancel </a>
                                </div>
                                <div className='documentModalSaveBtn'>
                                    <a onClick={() => handleOverSpeedApiCall()}> Save ⮕ </a>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </> : null}
            <div className="settings">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-xs-12">
                        <div class="card custom-card" style={{ 'height': "100%" }}>
                            <div className="card-body">
                                <h6 className="text-left">Change Password</h6>
                                <form style={{ 'height': "95%" }} onSubmit={formik.handleSubmit}>
                                    <div className="row p-3">
                                        <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-start flex-column mb-2">
                                            <label
                                                htmlFor={`current-password`}
                                                className="required custom-form-label"
                                            >
                                                Current Password
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    id="currentPassword"
                                                    name="currentPassword"
                                                    className={`form-control`}
                                                    value={formik.values.currentPassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span class="input-group-text" id="basic-addon1" className="input-group-text"
                                                    onClick={() => togglePasswordVisibility('current')}
                                                    style={{ cursor: 'pointer' }}
                                                >{passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}</span>
                                            </div>
                                            {formik.errors.currentPassword && formik.touched.currentPassword && (
                                                <div className="field-error">{formik.errors.currentPassword}</div>
                                            )}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-start flex-column mb-2">
                                            <label
                                                htmlFor={`new-password`}
                                                className="required custom-form-label"
                                            >
                                                New Password
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    type={newPasswordVisible ? 'text' : 'password'}
                                                    id="newPassword"
                                                    name="newPassword"
                                                    className={`form-control`}
                                                    value={formik.values.newPassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span class="input-group-text" id="basic-addon1" className="input-group-text"
                                                    onClick={() => togglePasswordVisibility('new')}
                                                    style={{ cursor: 'pointer' }}
                                                >{newPasswordVisible ? <MdVisibilityOff /> : <MdVisibility />}</span>
                                            </div>
                                            {formik.errors.newPassword && formik.touched.newPassword && (
                                                <div className="field-error">{formik.errors.newPassword}</div>
                                            )}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-start flex-column mb-2">
                                            <label
                                                htmlFor={`confirm-password`}
                                                className="required custom-form-label"
                                            >
                                                Confirm Password
                                            </label>
                                            <div class="input-group">
                                                <input
                                                    type={confirmPasswordVisible ? 'text' : 'password'}
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    className={`form-control`}
                                                    value={formik.values.confirmPassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <span class="input-group-text" id="basic-addon1" className="input-group-text"
                                                    onClick={() => togglePasswordVisibility('confirm')}
                                                    style={{ cursor: 'pointer' }}
                                                >{confirmPasswordVisible ? <MdVisibilityOff /> : <MdVisibility />}</span>
                                            </div>
                                            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                                                <div className="field-error">{formik.errors.confirmPassword}</div>
                                            )}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-end flex-column" style={{ "margin-top": "2rem" }}>
                                            <Button type="submit" className="btn custom-submit-btn ml-20">Save Changes</Button>{' '}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xs-12">
                        <div class="card custom-card">
                            <div className="card-body">
                                {/* <div className="d-flex justify-content-between">
                                <h6 className="text-left">Vehicle Notification</h6>
                                <div class="form-check form-switch">
                                    <input
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        name="vehicleNotification"
                                        className={`form-control form-check-input custom-switch`}
                                        value={formik.values.vehicleNotification}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div> */}
                                <div>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr className="text-left">
                                                <th scope="col" className="th-heading">Notification Event</th>
                                                {/* <th scope="col" className="th-heading">Email</th> */}
                                                <th scope="col" className="th-heading">Push</th>
                                            </tr>
                                        </thead>
                                        <tbody className="notifications">
                                            <tr className="text-left">
                                                <td>
                                                    <p className="mb-1 title">Ignition On/Off</p>
                                                    <p className="mb-0 details">Whenever ignition turns off.</p>
                                                </td>
                                                {/* <td className="p-checkbox"><input type="checkbox" value="" id="flexCheckDefault" className="custom-checkbox" /></td> */}
                                                <td className="p-checkbox"><input type="checkbox" checked={ignitionOnOff} id="flexCheckDefault" className="custom-checkbox" onChange={(e)=> setIgnitionOnOff(e.target.checked)} /></td>
                                            </tr>
                                            <tr className="text-left">
                                                <td>
                                                    <p className="mb-1 title">Low Fuel</p>
                                                    <p className="mb-0 details">Whenever fuel goes below 10 ltr.</p>
                                                </td>
                                                {/* <td className="p-checkbox"><input type="checkbox" value="" id="flexCheckDefault" className="custom-checkbox" /></td> */}
                                                <td className="p-checkbox"><input type="checkbox" value="" id="flexCheckDefault" className="custom-checkbox" /></td>
                                            </tr>
                                            <tr className="text-left">
                                                <td>
                                                    <p className="mb-1 title">OverSpeed</p>
                                                    <p className="mb-0 details">Whenever truck is in overspeeding.</p>
                                                </td>
                                                {/* <td className="p-checkbox"><input type="checkbox" value="" id="flexCheckDefault" className="custom-checkbox" /></td> */}
                                                <td className="p-checkbox"><input type="checkbox" checked={overSpeed} onChange={(e) => handleOverSpeedModal(e.target.checked)} id="flexCheckOverSpeed" className="custom-checkbox" /></td>
                                            </tr>
                                            <tr className="text-left">
                                                <td>
                                                    <p className="mb-1 title">Sudden fuel dropped</p>
                                                    <p className="mb-0 details">Whenever fuel level drops suddenly</p>
                                                </td>
                                                {/* <td className="p-checkbox"><input type="checkbox" value="" id="flexCheckDefault" className="custom-checkbox" /></td> */}
                                                <td className="p-checkbox"><input type="checkbox" id="flexCheckDefault" className="custom-checkbox" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xs-12 d-flex align-items-end flex-column">
                                    <Button type="button" onClick={handleSettingsApiCall} className="btn custom-submit-btn ml-20">Save Changes</Button>{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Setting;