const initialState = {collapsable: {collapsed: false}};

const getCollapsedState = (state = initialState, action) => {
    switch (action.type) {
        case "collapsed":
            return {collapsed: false}
        case "uncollapsed":
            return {collapsed: true}
        default:
            return state
    }
}

export default getCollapsedState;