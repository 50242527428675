let rtoArrayList = [
    "AN01",
    "AN02",
    "AP02",
    "AP03",
    "AP04",
    "AP05",
    "AP07",
	"AP08",
    "AP16",
    "AP18",
    "AP21",
    "AP26",
    "AP27",
    "AP30",
    "AP31",
	"AP32",
	"AP33",
	"AP34",
    "AP35",
    "AP37", 
	"AP38",
    "AP11Z",
    "AR01",
    "AR02",
    "AR03",
    "AR04",
    "AR05",
    "AR06",
    "AR07",
    "AR08",
    "AR09",
    "AR10",
    "AR11",
    "AR12",
    "AR13",
    "AR14",
    "AR15",
    "AR16",
    "AR17",
    "AR20",
    "AS01",
    "AS02",
    "AS03",
    "AS04",
    "AS05",
    "AS06",
    "AS07",
    "AS08",
    "AS09",
    "AS10",
    "AS11",
    "AS12",
    "AS13",
    "AS14",
    "AS15",
    "AS16",
    "AS17",
    "AS18",
    "AS19",
    "AS20",
    "AS21",
    "AS22",
    "AS23",
    "AS24",
    "AS25",
    "AS26",
    "AS27",
    "AS28",
    "AS29",
    "AS30",
    "AS31",
    "BR01",
    "BR02",
    "BR03",
    "BR04",
    "BR05",
    "BR06",
    "BR07",
    "BR08",
    "BR09",
    "BR10",
    "BR11",
    "BR16",
    "BR17",
    "BR18",
    "BR19",
    "BR20",
    "BR21",
    "BR22",
    "BR23",
    "BR24",
    "BR25",
    "BR26",
    "BR27",
    "BR28",
    "BR29",
    "BR30",
    "BR31",
    "BR32",
    "BR33",
    "BR34",
    "BR35",
    "BR36",
    "BR37",
    "BR38",
    "BR39",
    "BR40",
    "BR41",
    "BR42",
    "BR43",
    "BR44",
    "BR45",
    "BR46",
    "BR47",
    "BR50",
    "BR51",
    "BR52",
    "BR53",
    "BR54",
    "BR55",
    "BR56",
    "BR57",
    "CH01",
    "CH01G",
    "CH02",
    "CH03",
    "CH04",
    "CG01",
    "CG02",
    "CG03",
    "CG04",
    "CG05",
    "CG06",
    "CG07",
    "CG08",
    "CG09",
    "CG10",
    "CG11",
    "CG12",
    "CG13",
    "CG14",
    "CG15",
    "CG16",
    "CG17",
    "CG18",
    "CG19",
    "CG20",
    "CG21",
    "CG22",
    "CG23",
    "CG24",
    "CG26",
    "CG27",
    "CG28",
    "CG29",
    "CG30",
    "DN09",
    "DD02",
    "DD03",
    "DL1",
    "DL2",
    "DL3",
    "DL4",
    "DL5",
    "DL6",
    "DL7",
    "DL8",
    "DL9",
    "DL10",
    "DL11",
    "DL12",
    "DL13",
    "DL14",
    "DL15",
    "DL16",
    "DL17",
    "DL18",
    "DL30",
    "DL1C",
	"DL18C",
    "DL1L",
    "DL1N",
    "DL1P",
    "DL1R",
    "DL1S" ,
	"DL18S",
    "DL1T",
    "DL1V",
    "DL1Y",
    "GA01",
    "GA02",
    "GA03",
    "GA04",
    "GA05",
    "GA06",
    "GA07",
    "GA08",
    "GA09",
    "GA10",
    "GA11",
    "GA12",
    "GJ01",
    "GJ02",
    "GJ03",
    "GJ04",
    "GJ05",
    "GJ06",
    "GJ07",
    "GJ08",
    "GJ09",
    "GJ10",
    "GJ11",
    "GJ12",
    "GJ13",
    "GJ14",
    "GJ15",
    "GJ16",
    "GJ17",
    "GJ18",
    "GJ19",
    "GJ20",
    "GJ21",
    "GJ22",
    "GJ23",
    "GJ24",
    "GJ25",
    "GJ26",
    "GJ27",
    "GJ28",
    "GJ29",
    "GJ30",
    "GJ31",
    "GJ32",
    "GJ33",
    "GJ34",
    "GJ35",
    "GJ36",
    "GJ37",
    "GJ38",
    "HR01",
    "HR02",
    "HR03",
    "HR04",
    "HR05",
    "HR06",
    "HR07",
    "HR08",
    "HR09",
    "HR10",
    "HR11",
    "HR12",
    "HR13",
    "HR14",
    "HR15",
    "HR16",
    "HR17",
    "HR18",
    "HR19",
    "HR20",
    "HR21",
    "HR22",
    "HR23",
    "HR24",
    "HR25",
    "HR26",
    "HR27",
    "HR28",
    "HR29",
    "HR30",
    "HR31",
    "HR32",
    "HR33",
    "HR34",
    "HR35",
    "HR36",
    "HR37",
    "HR38",
    "HR39",
    "HR40",
    "HR41",
    "HR42",
    "HR43",
    "HR44",
    "HR45",
    "HR46",
    "HR47",
    "HR48",
    "HR49",
    "HR50",
    "HR51",
    "HR52",
    "HR53",
    "HR54",
    "HR55",
    "HR56",
    "HR57",
    "HR58",
    "HR59",
    "HR60",
    "HR61",
    "HR62",
    "HR63",
    "HR64",
    "HR65",
    "HR66",
    "HR67",
    "HR68",
    "HR69",
    "HR70",
    "HR71",
    "HR72",
    "HR73",
    "HR74",
    "HR75",
    "HR76",
    "HR77",
    "HR78",
    "HR79",
    "HR80",
    "HR81",
    "HR82",
    "HR83",
    "HR84",
    "HR85",
    "HR86",
    "HR87",
    "HR88",
    "HR89",
    "HR90",
    "HR91",
    "HR92",
    "HR93",
    "HR94",
    "HR95",
    "HR99",
	"HR100",
    "HP01", 
	"HP02",
    "HP03",
    "HP04",
    "HP05",
    "HP06",
    "HP07",
    "HP08",
    "HP09",
    "HP10",
    "HP11",
    "HP12",
    "HP13",
    "HP14",
    "HP15",
    "HP16",
    "HP17",
    "HP18",
    "HP19",
    "HP20",
    "HP21",
    "HP22",
    "HP23",
    "HP24",
    "HP25",
    "HP26",
    "HP27",
    "HP28",
    "HP29",
    "HP30",
    "HP31",
    "HP32",
    "HP33",
    "HP34",
    "HP35",
    "HP36",
    "HP37",
    "HP38",
    "HP39",
    "HP40",
    "HP41",
    "HP42",
    "HP43",
    "HP44",
    "HP45",
    "HP46",
    "HP47",
    "HP48",
    "HP49",
    "HP50",
    "HP51",
	"HP52",
    "HP53",
    "HP54",
    "HP55",
    "HP56",
    "HP57",
    "HP58",
    "HP62",
    "HP63",
    "HP64",
    "HP65",
    "HP66",
    "HP67",
    "HP68",
    "HP69",
	"HP70",
    "HP71",
    "HP72",
    "HP73",
    "HP74",
    "HP76",
    "HP77",
    "HP78",
    "HP79",
    "HP80",
    "HP81",
    "HP82",
    "HP83",
    "HP84",
    "HP85",
    "HP86",
    "HP90",
    "JK01",
    "JK02",
    "JK03",
    "JK04",
    "JK05",
    "JK06",
    "JK07",
    "JK08",
    "JK09",
    "JK10",
    "JK11",
    "JK12",
    "JK13",
    "JK14",
    "JK15",
    "JK16",
    "JK17",
    "JK18",
    "JK19",
    "JK20",
    "JK21",
    "JK22",
    "JK**Y",
    "JH01",
    "JH02",
    "JH03",
    "JH04",
    "JH05",
    "JH06",
    "JH07",
    "JH08",
    "JH09",
    "JH10",
    "JH11",
    "JH12",
    "JH13",
    "JH14",
    "JH15",
    "JH16",
    "JH17",
    "JH18",
    "JH19",
    "JH20",
    "JH21",
    "JH22",
    "JH23",
    "JH24",
    "KA01",
    "KA02",
    "KA03",
    "KA04",
    "KA05",
    "KA06",
    "KA07",
    "KA08",
    "KA09",
    "KA10",
    "KA11",
    "KA12",
    "KA13",
    "KA14",
    "KA15",
    "KA16",
    "KA17",
    "KA18",
    "KA19",
    "KA20",
    "KA21",
    "KA22",
    "KA23",
    "KA24",
    "KA25",
    "KA26",
    "KA27",
    "KA28",
    "KA29",
    "KA30",
    "KA31",
    "KA32",
    "KA33",
    "KA34",
    "KA35",
    "KA36",
    "KA37",
    "KA38",
    "KA39",
    "KA40",
    "KA41",
    "KA42",
    "KA43",
    "KA44",
    "KA45",
    "KA46",
    "KA47",
    "KA48",
    "KA49",
    "KA50",
    "KA51",
    "KA52",
    "KA53",
    "KA54",
    "KA55",
    "KA56",
    "KA57",
    "KA65",
    "KA66",
    "KA67",
    "KA##F",
	"KA##FA",
    "KA##G",
	"KA##GZ",
    "KL01",
    "KL02",
    "KL03",
    "KL04",
    "KL05",
    "KL06",
    "KL07",
    "KL08",
    "KL09",
    "KL10",
    "KL11",
    "KL12",
    "KL13",
    "KL14",
    "KL15",
    "KL16",
    "KL17",
    "KL18",
    "KL19",
    "KL20",
    "KL21",
    "KL22",
    "KL23",
    "KL24",
    "KL25",
    "KL26",
    "KL27",
    "KL28",
    "KL29",
    "KL30",
    "KL31",
    "KL32",
    "KL33",
    "KL34",
    "KL35",
    "KL36",
    "KL37",
    "KL38",
    "KL39",
    "KL40",
    "KL41",
    "KL42",
    "KL43",
    "KL44",
    "KL45",
    "KL46",
    "KL47",
    "KL48",
    "KL49",
    "KL50",
    "KL51",
    "KL52",
    "KL53",
    "KL54",
    "KL55",
    "KL56",
    "KL57",
    "KL58",
    "KL59",
    "KL60",
    "KL61",
    "KL62",
    "KL63",
    "KL64",
    "KL65",
    "KL66",
    "KL67",
    "KL68",
    "KL69",
    "KL70",
    "KL71",
    "KL72",
    "KL73",
    "KL74",
    "KL75",
    "KL76",
    "KL77",
    "KL78",
    "KL79",
    "KL80",
    "KL81",
    "KL82",
    "KL83",
    "KL84",
    "KL85",
    "LD01",
    "LD02",
    "LD03",
    "LD04",
    "LD05",
    "LD06",
    "LD07",
    "LD08",
    "LD09",
    "MP01",
    "MP02",
    "MP03",
    "MP04",
    "MP05",
    "MP06",
    "MP07",
    "MP08",
    "MP09",
    "MP10",
    "MP11",
    "MP12",
    "MP13",
    "MP14",
    "MP15",
    "MP16",
    "MP17",
    "MP18",
    "MP19",
    "MP20",
    "MP21",
    "MP22",
    "MP23",
    "MP24",
    "MP25",
    "MP26",
    "MP27",
    "MP28",
    "MP29",
    "MP30",
    "MP31",
    "MP32",
    "MP33",
    "MP34",
    "MP35",
    "MP36",
    "MP37",
    "MP38",
    "MP39",
    "MP40",
    "MP41",
    "MP42",
    "MP43",
    "MP44",
    "MP45",
    "MP46",
    "MP47",
    "MP48",
    "MP49",
    "MP50",
    "MP51",
    "MP52",
    "MP53",
    "MP54",
    "MP65",
    "MP66",
    "MP67",
    "MP68",
    "MP69",
    "MP70",
    "MH01",
    "MH02",
    "MH03",
    "MH04",
    "MH05",
    "MH06",
    "MH07",
    "MH08",
    "MH09",
    "MH10",
    "MH11",
    "MH12",
    "MH13",
    "MH14",
    "MH15",
    "MH16",
    "MH17",
    "MH18",
    "MH19",
    "MH20",
    "MH21",
    "MH22",
    "MH23",
    "MH24",
    "MH25",
    "MH26",
    "MH27",
    "MH28",
    "MH29",
    "MH30",
    "MH31",
    "MH32",
    "MH33",
    "MH34",
    "MH35",
    "MH36",
    "MH37",
    "MH38",
    "MH39",
    "MH40",
    "MH41",
    "MH42",
    "MH43",
    "MH44",
    "MH45",
    "MH46",
    "MH47",
    "MH48",
    "MH49",
    "MH50",
    "MH51",
    "MH52",
    "MH53",
    "MH54",
    "MH55",
    "MH56",
    "MH57",
    "MH58",
    "MN01",
    "MN02",
    "MN03",
    "MN04",
    "MN05",
    "MN06",
    "MN07",
    "ML01",
    "ML02",
    "ML03",
    "ML04",
    "ML05",
    "ML06",
    "ML07",
    "ML08",
    "ML09",
    "ML10",
    "MZ01",
    "MZ02",
    "MZ03",
    "MZ04",
    "MZ05",
    "MZ06",
    "MZ07",
    "MZ08",
    "NL01",
    "NL02",
    "NL03",
    "NL04",
    "NL05",
    "NL06",
    "NL07",
    "NL08",
    "OR01",
    "OR02",
    "OR03",
    "OR04",
    "OR05",
    "OR06",
    "OR07",
    "OR08",
    "OR09",
    "OR10",
    "OR11",
    "OR12",
    "OR13",
    "OR14",
    "OR15",
    "OR16",
    "OR17",
    "OR18",
    "OR19",
    "OR20",
    "OR21",
    "OR22",
    "OR23",
    "OR24",
    "OR25",
    "OR26",
    "OR27",
    "OR28",
    "OR29",
    "OR30",
    "OR31",
    "OD32",
    "OD33",
    "OD34",
    "OD35",
    "PY01",
    "PY02",
    "PY03",
    "PY04",
    "PY05",
    "PB01",
    "PB02",
    "PB03",
    "PB04",
    "PB05",
    "PB06",
    "PB07",
    "PB08",
    "PB09",
    "PB10",
    "PB11",
    "PB12",
    "PB13",
    "PB14",
    "PB15",
    "PB16",
    "PB17",
    "PB18",
    "PB19",
    "PB20",
    "PB21",
    "PB22",
    "PB23",
    "PB24",
    "PB25",
    "PB26",
    "PB27",
    "PB28",
    "PB29",
    "PB30",
    "PB31",
    "PB32",
    "PB33",
    "PB34",
    "PB35",
    "PB36",
    "PB37",
    "PB38",
    "PB39",
    "PB40",
    "PB41",
    "PB42",
    "PB43",
    "PB44",
    "PB45",
    "PB46",
    "PB47",
    "PB48",
    "PB49",
    "PB50",
    "PB51",
    "PB52",
    "PB53",
    "PB54",
    "PB55",
    "PB56",
    "PB57",
    "PB58",
    "PB59",
    "PB60",
    "PB61",
    "PB62",
    "PB63",
    "PB64",
    "PB65",
    "PB66",
    "PB67",
    "PB68",
    "PB69",
    "PB70",
    "PB71",
    "PB72",
    "PB73",
    "PB74",
    "PB75",
    "PB76",
    "PB77",
    "RJ01",
    "RJ02",
    "RJ03",
    "RJ04",
    "RJ05",
    "RJ06",
    "RJ07",
    "RJ08",
    "RJ09",
    "RJ10",
    "RJ11",
    "RJ12",
    "RJ13",
    "RJ14",
    "RJ15",
    "RJ16",
    "RJ17",
    "RJ18",
    "RJ19",
    "RJ20",
    "RJ21",
    "RJ22",
    "RJ23",
    "RJ24",
    "RJ25",
    "RJ26",
    "RJ27",
    "RJ28",
    "RJ29",
    "RJ30",
    "RJ31",
    "RJ32",
    "RJ33",
    "RJ34",
    "RJ35",
    "RJ36",
    "RJ37",
    "RJ38",
    "RJ39",
    "RJ40",
    "RJ41",
    "RJ42",
    "RJ43",
    "RJ44",
    "RJ45",
    "RJ46",
    "RJ47",
    "RJ48",
    "RJ49",
    "RJ50",
    "RJ51",
    "RJ52",
    "SK01",
    "SK02",
    "SK03",
    "SK04",
    "SK05",
    "SK06",
    "TN01",
    "TN02",
    "TN03",
    "TN04",
    "TN05",
    "TN06",
    "TN07",
    "TN09",
    "TN10",
    "TN11",
    "TN16",
    "TN18",
    "TN19",
    "TN20",
    "TN21",
    "TN22",
    "TN23",
    "TN24",
    "TN25",
    "TN27",
    "TN28",
    "TN29",
    "TN30",
    "TN31",
    "TN32",
    "TN33",
    "TN34",
    "TN36",
    "TN37",
    "TN38",
    "TN39",
    "TN40",
    "TN41",
    "TN42",
    "TN43",
    "TN45",
    "TN46",
    "TN47",
    "TN48",
    "TN49",
    "TN50",
    "TN51",
    "TN52",
    "TN54",
    "TN55",
    "TN56",
    "TN57",
    "TN58",
    "TN59",
    "TN60",
    "TN61",
    "TN63",
    "TN64",
    "TN65",
    "TN66",
    "TN67",
    "TN68",
    "TN69",
    "TN70",
    "TN72",
    "TN73",
    "TN74",
    "TN75",
    "TN76",
    "TN77",
    "TN78",
    "TN79",
    "TN81",
    "TN88",
    "TN90",
    "TN91",
    "TN92",
    "TN99",
    "TR01",
    "TR02",
    "TR03",
    "TR04",
    "TR05",
    "TR06",
    "TR07",
    "TR08",
    "TS01",
    "TS02",
    "TS03",
    "TS04",
    "TS05",
    "TS06",
    "TS07",
    "TS08",
    "TS09",
    "TS10",
    "TS11",
    "TS12",
    "TS13",
    "TS14",
    "TS15",
    "TS16",
    "TS17",
    "TS19",
    "TS19",
    "TS20",
    "TS21",
    "TS22",
    "TS23",
    "TS24",
    "TS25",
    "TS26",
    "TS27",
    "TS28",
    "TS29",
    "TS30",
    "TS31",
    "TS32",
    "TS33",
    "TS34",
    "TS35",
    "TS36",
    "UK01",
    "UK02",
    "UK03",
    "UK04",
    "UK05",
    "UK06",
    "UK07",
    "UK08",
    "UK09",
    "UK10",
    "UK11",
    "UK12",
    "UK13",
    "UK14",
    "UK15",
    "UK16",
    "UK17",
    "UK18",
    "UK19",
    "UP01",
    "UP02",
    "UP03",
    "UP04",
    "UP05",
    "UP06",
    "UP07",
    "UP08",
    "UP09",
    "UP10",
    "UP11",
    "UP12",
    "UP13",
    "UP14",
    "UP15",
    "UP16",
    "UP17",
    "UP18",
    "UP19",
    "UP20",
    "UP21",
    "UP22",
    "UP23",
    "UP24",
    "UP25",
    "UP26",
    "UP27",
    "UP28",
    "UP29",
    "UP30",
    "UP31",
    "UP32",
    "UP33",
    "UP33G",
    "UP33T",
    "UP34",
    "UP35",
    "UP35B",
    "UP36",
    "UP37",
    "UP40",
    "UP41",
    "UP42",
    "UP43",
    "UP44",
    "UP45",
    "UP46",
    "UP47",
    "UP50",
    "UP50G",
    "UP50T",
    "UP51",
    "UP52",
    "UP53",
    "UP53T",
    "UP53AG",
    "UP54",
    "UP55",
    "UP56",
    "UP57",
    "UP58",
    "UP59",
    "UP60",
    "UP61",
    "UP62",
    "UP62T",
    "UP63",
    "UP64",
    "UP65",
    "UP66",
    "UP67",
    "UP68",
    "UP69",
    "UP70",
    "UP71",
    "UP72",
    "UP73",
    "UP74",
    "UP75",
    "UP75AT",
    "UP75G",
    "UP76",
    "UP77",
    "UP78",
    "UP78G",
    "UP78A",
    "UP79",
    "UP80",
    "UP81",
    "UP82",
    "UP83",
    "UP84",
    "UP85",
    "UP86",
    "UP87",
    "UP88",
    "UP89",
    "UP90",
    "UP91",
    "UP92",
    "UP93",
    "UP93E",
    "UP93AG",
    "UP94",
    "UP95",
    "UP95B",
    "UP95G",
    "UP96",
    "WB01",
    "WB02",
    "WB03",
    "WB04",
    "WB05",
    "WB06",
    "WB07",
    "WB08",
    "WB09",
    "WB10",
    "WB11",
    "WB12",
    "WB13",
    "WB14",
    "WB15",
    "WB16",
    "WB18",
    "WB19",
    "WB20",
    "WB22",
    "WB23",
    "WB24",
    "WB25",
    "WB26",
    "WB29",
    "WB30",
    "WB31",
    "WB32",
    "WB33",
    "WB34",
    "WB37",
    "WB38",
    "WB39",
    "WB40",
    "WB41",
    "WB42",
    "WB44",
    "WB51",
    "WB52",
    "WB53",
    "WB54",
    "WB55",
    "WB56",
    "WB57",
    "WB58",
    "WB59",
    "WB60",
    "WB61",
    "WB62",
    "WB63",
    "WB64",
    "WB65",
    "WB66",
    "WB67",
    "WB68",
    "WB69",
    "WB70",
    "WB71",
    "WB72",
    "WB73",
    "WB74",
    "WB76",
    "WB77",
    "WB78",
    "WB79",
    "WB89",
    "WB90",
    "WB91",
    "WB92",
    "WB93",
    "WB94",
    "WB95",
    "WB96",
    "WB97",
    "WB98"
]

export default rtoArrayList;