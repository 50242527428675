export const changeLanguageHindi = (language) => {
    return {
      type: "languageHindi",
      payload: {
        language: language
      },
    };
  };

export const changeLanguageEnglish = (language) => {
    return {
      type: "languageEnglish",
      payload: {
        language: language
      },
    };
  };